import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Link } from 'react-router-dom';
import '../../css/account.css';
import blankImg from '../../images/blank_img.png';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

const Vouchers = () => {
    return (
        <>

            <Navbar />

            <div className='container' style={{ marginTop: "5rem", marginBottom: "5rem" }}>
                <div className='card' id="account_card">
                    <div className='card-body'>
                        <h4>Orders</h4>
                        <hr />

                        <Tabs>
                            <TabList>
                                <Tab>ACTIVE (0)</Tab>
                                <Tab>INACTIVE (0)</Tab>
                            </TabList>

                            {/* For Open Orders */}
                            <TabPanel>
                                <div className='container text-center pt-5 pb-5'>
                                    <img src={blankImg} alt="..." width="150px" />

                                    <p><strong>You currently have no Credit or Vouchers</strong></p>

                                    <small>All your available Mingoblox credit and vouchers will be displayed here</small>

                                    <div className='mt-5'>
                                        <Link to="/mingo_robot" id="continue_shopping">CONTINUE SHOPPING</Link>
                                    </div>
                                </div>
                            </TabPanel>

                            {/* For Closed Orders */}
                            <TabPanel>
                                <div className='container text-center pt-5 pb-5'>
                                    <img src={blankImg} alt="..." width="150px" />

                                    <p><strong>You currently have no Credit or Vouchers</strong></p>

                                    <small>All your available Mingoblox credit and vouchers will be displayed here</small>

                                    <div className='mt-5'>
                                        <Link to="/mingo_robot" id="continue_shopping">CONTINUE SHOPPING</Link>
                                    </div>
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>


            <Footer />
        </>
    )
}

export default Vouchers