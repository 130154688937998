import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import '../css/signin.css';
import Swal from 'sweetalert2';
import axios from "axios";






const SignUp = () => {


    const [theEmail, setTheEmail] = useState();
    const [userData, setUserData] = useState();



    //Fetch Email from Local Storage
    useEffect(() => {

        //Redirect is already signed in
        var token = localStorage.getItem("token");

        if(!token)
        {
            //Do Nothing
        }
        else
        {
            window.location.href = "/mingo_robot";
        }

        var getEmail = localStorage.getItem("userEmail");
        setTheEmail(getEmail);

    }, []);



    //Sign Up Function
    const doSignUp = () => {

        var emailField = document.getElementById("theEmail").value;
        var passField = document.getElementById("thePass").value;
        var cPassField = document.getElementById("theCPass").value;
    
        if (emailField === "") {
            Swal.fire({
                toast: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 5000,
                icon: "info",
                title: "Ooops!!",
                text: "The Email Field is Empty!",
            })
        }
        else if (passField === "") {
            Swal.fire({
                toast: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 5000,
                icon: "info",
                title: "Ooops!!",
                text: "Please enter a password!",
            })
        }
        else if (cPassField === "") {
            Swal.fire({
                toast: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 5000,
                icon: "info",
                title: "Ooops!!",
                text: "Please confirm your password!",
            })
        }
        else if (passField !== cPassField) {
            Swal.fire({
                toast: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 5000,
                icon: "info",
                title: "Ooops!!",
                text: "Passwords do not match!",
            })
        }
        else
        {
            var firstCollect = JSON.stringify({
                "email": emailField,
                "password": passField
            });

            localStorage.setItem("firstCollect", firstCollect);

            document.getElementById("signUpSection").style.display = "none";
            document.getElementById("almostThere").style.display = "block";
        }

    }



    //Almost There Function
    const doCont1 = () => {

        var firstCollect = JSON.parse(localStorage.getItem("firstCollect"));
        var firstName = document.getElementById("theFName").value;
        var lastName = document.getElementById("theLName").value;
        var contact = document.getElementById("theCNum").value;

        var counCode = document.getElementById("countryCode");
        var selectedCode = counCode.options[counCode.selectedIndex].text;

        if(firstName === "" || lastName === "" || contact === "")
        {
            Swal.fire({
                toast: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 5000,
                icon: "info",
                title: "Ooops!!",
                text: "Looks like you didn't fill out all the fields",
            })
        }
        else
        {
            var gatherData = {
                first_name: firstName,
                last_name: lastName,
                country_code: selectedCode,
                phone: contact,
                ...firstCollect
            }

            setUserData(gatherData);

            document.getElementById("almostThere").style.display = "none";
            document.getElementById("finalStep").style.display = "block";
        }

    }




    //Finish Function
    const doFinish = () => {

        var dateofBirth = document.getElementById("theDOB").value;
        var gen = document.getElementById("gender");
        var gender = gen.options[gen.selectedIndex].text;

        if (dateofBirth === "" || gender === "Select Gender...")
        {
            Swal.fire({
                toast: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 5000,
                icon: "info",
                title: "Ooops!!",
                text: "Looks like you didn't fill out all the fields",
            })
        }
        else
        {
            var allData = {
                dob: dateofBirth,
                gender: gender,
                ...userData
            }

            document.getElementById("loaderIcon4").style.display = "block";

            axios.post("https://gblox.herokuapp.com/gblox/auth/signup", allData)
            .then((response) => {

                var userData = JSON.stringify(response.data.payload);
                localStorage.setItem("userData", userData);

                var tkn = response.data.token;
                localStorage.setItem("token", tkn);

                window.location.href = '/mingo_robot';

                localStorage.removeItem("userEmail");
                localStorage.removeItem("firstCollect");
                
            }).catch((error) => {

                if(error.response.status !== 200) {
                    Swal.fire({
                        title: 'Error...',
                        text: error.response.data.error,
                        icon: 'error',
                        allowOutsideClick: false,
                        cancelButtonColor: '#747474',
                        confirmButtonText: "Sign In"
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                          window.location.href = '/signin';
                        }
                    });

                    document.getElementById("loaderIcon4").style.display = "none";
                }

            });
        }

    }








  return (
    
    <>
        <Navbar />


        <div style={{overflow: "hidden"}}>

            <div id="signUpSection">
                <div className='container text-center' id="textHolder">
                    <h3 id='signHead'>Create your account</h3>

                    <p id="signSub">Let's get started by creating your account. <br /> To keep your account safe, we advice you add a strong password</p>
                </div>

                <div className='container text-center mt-5' id="formsContainer">
                    <div class="form-group pt-3">
                        <input type="text" class="form-control" id="theEmail" placeholder="Email" defaultValue={theEmail} />
                    </div>

                    <div class="form-group pt-3">
                        <input type="password" class="form-control" id="thePass" placeholder="Password" required />
                    </div>

                    <div class="form-group pt-3">
                        <input type="password" class="form-control" id="theCPass" placeholder="Confirm Password" required />
                    </div>

                    <div class="form-group pt-3 mt-4">
                        <Link to="#" id='signIn-btn' onClick={doSignUp}>Continue</Link>
                    </div>
                </div>
            </div>

        </div>





        <div style={{overflow: "hidden"}}>

            {/* Almost There */}
            <div id="almostThere" style={{display: "none"}}>
                <div className='container text-center' id="textHolder">
                    <h3 id='signHead'>Almost there</h3>

                    <p id="signSub">Your details (Stays private)</p>
                </div>
        
                <div className='container text-center mt-5' id="formsContainer">

                    <div class="form-group pt-3">
                        <input type="text" class="form-control" id="theFName" placeholder="First Name" required />
                    </div>

                    <div class="form-group pt-3">
                        <input type="text" class="form-control" id="theLName" placeholder="Last Name" required />
                    </div>

                    <div className='row'>
                        <div className='col-md-4'>
                            <div class="from-group pt-3">
                                <select class="form-select" id="countryCode">
                                    <option selected>+233</option>
                                    <option>+225</option>
                                    <option>+228</option>
                                    <option>+234</option>
                                </select>
                            </div>
                        </div>  

                        <div className='col-md-8'>
                            <div class="form-group pt-3">
                                <input type="text" class="form-control" id="theCNum" placeholder="Contact Number" required />
                            </div>
                        </div>
                    </div>

                    <div class="form-group pt-3 mt-4">
                        <Link to="#" id='signIn-btn' onClick={doCont1}>Continue</Link>
                    </div>
                </div>
            </div>

        </div>





        <div style={{overflow: "hidden"}}>

            {/* Final Step */}
            <div id="finalStep" style={{display: "none"}}>
                <div className='container text-center' id="textHolder">
                    <h3 id='signHead'>Final Step</h3>

                    <p id="signSub">Finish Setting up you account</p>
                </div>
        
                <div className='container mt-5' id="formsContainer">
                    <div className='text-center' id="loaderIcon4" style={{display: "none"}}>
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>

                    <div class="form-group pt-3">
                        <small>Date of Birth</small>
                        <input type="date" class="form-control" id="theDOB" />
                    </div>

                    <div class="from-group pt-3 text-center">
                        <select class="form-control" id="gender">
                            <option>Select Gender...</option>
                            <option>Male</option>
                            <option>Female</option>
                        </select>
                    </div>

                    <div class="form-group pt-3 mt-4 text-center">
                        <Link to="#" id='signIn-btn' onClick={doFinish}>Finish</Link>
                    </div>
                </div>
            </div>

        </div>






        <div style={{overflow: "hidden"}}>
        
            {/* Verification Code */}
            <div id="verifySection" style={{display: "none"}}>
                <div className='container text-center' id="textHolder">
                    <h3 className='pt-4' id='signHead'>Enter Verification Code</h3>

                    <p id="signSub">A verification code has been sent to your Email. Please enter it below to proceed</p>
                </div>

                <div className='container text-center mt-5' id="formsContainer">
                    <div class="form-group pt-3">
                        <input type="text" class="form-control" id="theFName" placeholder="Verification Code" required />
                    </div>

                    <div class="form-group pt-3 mt-4">
                        <Link to="#" id='signIn-btn'>Continue</Link>
                    </div>
                </div>
            </div>

            
        </div>







        <div className='container text-center' id="footHolder">
            <p id="footText">
                For further support, you may visit the <strong>Mingo Help Center</strong> or contact our
                customer service team.
            </p>
        </div>




    </>

  )

}

export default SignUp