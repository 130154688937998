import React from 'react';
import '../css/style.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import commentImg from '../images/commentImg.png';




const Testimonials = () => {



  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]

  };




  return (
    <>
      <div className='container-fluid pt-5 pb-5' style={{ backgroundColor: "#F9F9FF" }}>
        <div className='container pt-4'>

          <div className='row pb-5'>
            <div className='col-md-4 pt-3'>
              <h2 className='pt-2' id='servicesHead'>
                <strong>Hear What our Customers Are Saying</strong>
              </h2>

              <p className='pt-3' id='servicesTSub'>
                For 13+ years, our programs have been trusted parents and students alike
              </p>
            </div>

            <div className='col-md-8'>

              <div className="row">
                <Slider {...settings} id="dSlider">
                  <div className="col-md-4 pt-4" style={{ backgroundColor: "transparent" }}>
                    <div className='card' id="testyCard">
                      <div className='card-body' id="testyCardBody">
                        <div className='row'>
                          <div className='col-sm-4'>
                            <img src={commentImg} alt='userImg' />
                          </div>
                        </div>

                        <p className='pt-4' id='testyText'>
                          The robotics program offered at Mingo Blox's Robocentre was a
                          fantastic opportunity for my Kid.  It gave her a solid foundation
                          to build her academic career in Engineering. <br /><br />
                        </p>

                        <h3 className='pt-1' id='testyHead'>Stephen Agyapong</h3>

                        <p id='testySub'>Parent, Accra</p>
                      </div>
                    </div>
                  </div>


                  <div className="col-md-4 pt-4" style={{ backgroundColor: "transparent" }}>
                    <div className='card' id="testyCard">
                      <div className='card-body' id="testyCardBody">
                        <div className='row'>
                          <div className='col-sm-4'>
                            <img src={commentImg} alt='userImg' />
                          </div>
                        </div>

                        <p className='pt-4' id='testyText'>
                          The experience my kids have had with Mingo Blox is amazing:
                          not only has it better prepared them for the future of
                          Technology but it has also taught them important
                          life skills to solve real world problems.
                        </p>

                        <h3 className='pt-1' id='testyHead'>Alex Mensah</h3>

                        <p id='testySub'>Parent, Cape Coast</p>
                      </div>
                    </div>
                  </div>


                </Slider>
              </div>

            </div>
          </div>

        </div>
      </div>
    </>
  )

}

export default Testimonials