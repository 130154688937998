import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import s1 from '../images/cnn.png';
import s2 from '../images/s2.png';
import s3 from '../images/s3.png';
import s5 from '../images/s5.png';
import s6 from '../images/s6.png';
import s7 from '../images/s7.png';
import s8 from '../images/s8.png';
import s9 from '../images/s9.png';

const Sponsors = () => {


    const settings = {
        className: "center",
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 6,
        swipeToSlide: true,
        arrows: false,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
    };



  return (
    <>
        <div className='container-fluid pt-2 pb-2' id="sponsors" style={{paddingLeft: "8rem", paddingRight: "8rem"}}>
            <div className='container' style={{paddingLeft: "3rem", paddingRight: "3rem"}}>
                <section className='pt-3 pb-3' id="main">   

                    <Slider {...settings}>
                            <div className="showcase-box">
                                <a href="https://edition.cnn.com/videos/world/2020/01/16/ghana-science-epilepsy-inside-africa.cnn?fbclid=IwAR38KFOSHXWqPJOIwlXVsnJ_aUoqFuzzSA8TdgMXsDXp7rswctOCCOSKQZU" rel="noreferrer" target="_blank">
                                <img src={s1} alt="" />
                                </a>
                        </div>

                            <div className="showcase-box">
                                <a href="https://www.newtimes.co.rw/section/read/199862" rel="noreferrer" target="_blank">
                                    <img src={s2} alt="" />
                                </a>
                            </div>

                                    <div className="showcase-box">
                                        <a href="https://www.aljazeera.com/program/innovate-africa/2014/12/14/tech-teaching" rel="noreferrer" target="_blank">
                                            <img src={s3} alt="" />
                                        </a>
                                    </div>

                                    <div className="showcase-box">
                                        <a href="https://www.theguardian.com/world/2013/nov/07/ghana-accra-technology-startup-robot" rel="noreferrer" target="_blank">
                                            <img src={s5} alt="" />
                                        </a>
                                    </div>

                                    <div className="showcase-box">
                                        <a href="https://www.cnbcafrica.com/2019/this-is-how-technology-can-accelerate-universal-healthcare-in-africa/" rel="noreferrer" target="_blank">
                                            <img src={s6} alt="" />
                                        </a>
                                    </div>

                                    <div className="showcase-box">
                                        <a href="https://www.orfonline.org/wp-content/uploads/2020/01/KR_Book-27-01-2020_compressed.pdf" rel="noreferrer" target="_blank">
                                            <img src={s7} alt="" />
                                        </a>
                                    </div>

                                    <div className="showcase-box">
                                        <a href="https://www.orfonline.org/wp-content/uploads/2020/01/KR_Book-27-01-2020_compressed.pdf" rel="noreferrer" target="_blank">
                                            <img src={s8} alt="" />
                                        </a>
                                    </div>


                                    <div className="showcase-box">
                                        <a href="https://edition.cnn.com/videos/world/2020/01/16/ghana-science-epilepsy-inside-africa.cnn?fbclid=IwAR38KFOSHXWqPJOIwlXVsnJ_aUoqFuzzSA8TdgMXsDXp7rswctOCCOSKQZU" rel="noreferrer" target="_blank">
                                            <img src={s9} alt="" />
                                        </a>
                                    </div>
                    </Slider>
                            
                </section>
            </div>
        </div>

    </>
  )
}

export default Sponsors