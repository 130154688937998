import React, { useEffect, useState } from 'react';
import { theToken } from '../store/Creds';
import { Link } from 'react-router-dom';
import '../css/style.css';
import logo from '../images/mingo-logo.png';
import roboIcon from '../images/robocentre.png';
import cartIcon from '../images/cart.png';
import mingoPack from '../images/mingoPack.png';
import celloImg from '../images/celloImg.png';
import emptyCartImg from '../images/empty-cart.png';
import mainRobo from '../images/mainRobo.png';
import Swal from 'sweetalert2';


const Navbar = () => {


    //const [count, setCount] = useState(1);
    const [product, setProduct] = useState();
    const [subTotal, setSubTotal] = useState();
    const [total, setTotal] = useState();
    const [country, setCountry] = useState("78");
    const [loaded, setLoaded] = useState(false);
    const shipping = 0;



    //Get Data from Storage onLoad
    useEffect(() => {

        var token = localStorage.getItem("token");

        if (!token) {
            document.getElementById("signinDiv").style.display = "block";
            document.getElementById("signOutDiv").style.display = "none";
        }
        else {
            document.getElementById("signOutDiv").style.display = "block";
            document.getElementById("signinDiv").style.display = "none";
        }

    }, []);





    //Get Product Details
    useEffect(() => {

        //Get country


        var gotData = localStorage.getItem("productInfo");
        var realData = JSON.parse(gotData);

        if (gotData) {
            setProduct(realData);
            //console.log(realData)
            var calcSubTotal = realData[0]['cost'] * realData[0]['quantity']
            setSubTotal(calcSubTotal);

            var calcTotal = (realData[0]['cost'] * realData[0]['quantity']) + shipping;
            setTotal(calcTotal);

            setLoaded(true);
        }
        else {
            setLoaded(false);
        }

    }, []);



    //Delete product
    const doDelete = () => {

        localStorage.removeItem("productInfo");
        Swal.fire({
            toast: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            icon: "info",
            text: "Item has been deleted from cart",
        }).then(
            setTimeout(function () {
                window.location.href = "/mingo_robot";
            }, 1000)
        );

    }




    //Go to Checkout
    const goCheck = () => {

        //Check if already signedin

        if (!theToken) {
            //if not signed in

            var orderData = JSON.stringify({
                _id: product[0]['_id'],
                name: product[0]['name'],
                cost: product[0]['cost'],
                shipping: 0.00,
                subTotal: subTotal,
                quantity: product[0]['quantity'],
                total: total
            });

            function toStorage() {
                localStorage.setItem("orderData2", orderData);
            }

            toStorage();
            //console.log(JSON.parse(orderData));

            window.location.href = '/signin';

        }
        else {
            //if signed in
            var orderData2 = JSON.stringify({
                _id: product[0]['_id'],
                name: product[0]['name'],
                cost: product[0]['cost'],
                shipping: 0.00,
                subTotal: subTotal,
                quantity: product[0]['quantity'],
                total: total
            });

            function toStorage() {
                localStorage.setItem("orderData2", orderData2);
            }

            toStorage();

            window.location.href = '/checkout';
        }


    }





    //Do Sign Out
    const doSignOut = () => {

        localStorage.clear();

        Swal.fire({
            toast: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 5000,
            icon: "success",
            text: "You signed out of your account",
        }).then(
            setTimeout(function () {
                window.location.href = "/";
            }, 1000)
        );

    }








    return (
        <>
            <nav className="navbar navbar-dark navbar-expand-sm bg-white fixed-top">
                <div className="container" id="navCont">
                    <a href="/" className="navbar-brand">
                        <img id="theLogo" src={logo} alt="logo" />
                    </a>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span className="fa fa-bars" style={{ color: "#0000dc" }}></span>
                    </button>


                    <div id="navbarCollapse" className="collapse navbar-collapse">
                        <ul className="navbar-nav mx-auto">
                            <li className="nav-item dropdown pt-1" id="dropdownPro2">
                                <Link to="#" className="nav-link active" id="navbarDropdown2" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    COMPANY
                                </Link>

                                <div className="dropdown-menu border-0" id="dropdown-menu" aria-labelledby="navbarDropdown2" style={{ width: "500px", backgroundColor: "#efefef", borderRadius: "0px", marginTop: "0" }}>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <p className="dropdown-item" style={{ fontWeight: "bold", marginBottom: "0px" }}>About</p>
                                            <a className="dropdown-item" href="/about#whoWeAre">Who We Are</a>
                                            <a className="dropdown-item" href="/about#theVision">Our Vision</a>
                                            <a className="dropdown-item" href="/about#theMission">Our Mission</a>
                                        </div>

                                        <div className='col-md-6'>
                                            <p className="dropdown-item" style={{ fontWeight: "bold", marginBottom: "0px" }}>Contact Us</p>
                                            <a className="dropdown-item" href="/contact">Contact</a>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="nav-item pt-1" id="dropdownPro">
                                <Link to="#" className="nav-link active" id="navbarDropdown3" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    SOLUTION
                                </Link>

                                <div className="dropdown-menu border-0" id="dropdown-menu" aria-labelledby="navbarDropdown3" style={{ width: "540px", marginLeft: "15rem", backgroundColor: "#efefef", borderRadius: "0px", marginTop: "0" }}>
                                    <div className='row text-center' style={{ margin: "1rem" }}>
                                        <div className='col-md-6'>
                                            <strong><p>Educational Robot</p></strong>

                                            <div className='container mb-4'>
                                                <a href="/mingo_robot" style={{ textDecoration: "none" }}>
                                                    <div className='card' id="navCardMain" style={{ width: "200px" }}>
                                                        <div className='card-body' id="navCard">
                                                            <img src={mingoPack} alt='...' width='70%' />
                                                        </div>

                                                        <p className='text-center pt-2 text-white'>Mingo Robot</p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>

                                        <div className='col-md-6'>
                                            <strong><p>Social Robot</p></strong>

                                            <div className='container mb-4'>
                                                <Link to="#" style={{ textDecoration: "none" }}>
                                                    <div className='card' id="navCardMain2" style={{ width: "200px" }}>
                                                        <div className='card-body' id="navCard2">
                                                            <img src={celloImg} alt='...' width='60%' />
                                                        </div>

                                                        <p className='text-center pt-2 text-white'>Cello</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="nav-item pt-1">
                                <a href="/gblox" className="nav-link active">
                                    SOFTWARE
                                </a>
                            </li>

                            <li className="nav-item pt-1">
                                <a href="/mingo_robot" className="nav-link active">
                                    SHOP
                                </a>
                            </li>

                        </ul>



                        <ul className='navbar-nav ms-auto'>
                            <li className="nav-item">
                                <Link to="#" className="nav-link">
                                    <img src={roboIcon} alt="roboIcon" />
                                </Link>
                            </li>

                            <li className="nav-item pt-1">
                                <Link to="#" className="nav-link" data-bs-toggle="modal" data-bs-target="#myModal2">
                                    <img src={cartIcon} alt="cartIcon" width="25px" />
                                    {(!loaded && <span id="orderNum">0</span>) || <span id="orderNum">{product.length}</span>}
                                </Link>
                            </li>

                            {/*<li className='nav-item'>

                                    <div id="google_translate_element"></div>

                                </li>*/}
                        </ul>

                        <div className="nav-item" id="signinDiv">
                            <a href="/signin" className="nav-link active text-center" id="signin-btn">
                                Sign In
                            </a>
                        </div>


                        <div className="dropdown nav-item" id="signOutDiv" style={{ display: "none" }}>
                            <Link className="nav-link active text-center" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" id="userIcon">
                                <i className='fa fa-user'></i>
                            </Link>

                            <ul className="dropdown-menu" id="d_dropdown">
                                {/*<li><Link className="dropdown-item" to="/account"><i className="fa fa-user"></i> My Account</Link></li>
                                <li><Link className="dropdown-item" to="/orders"><i className="fa fa-list"></i> My Orders</Link></li>
                                <li><Link className="dropdown-item" to="/whishlist"><i className="fa fa-heart"></i> Wishlist</Link></li>
                                <li><Link className="dropdown-item" to="/vouchers"><i className="fa fa-gift"></i> Vouchers</Link></li>
                                <hr />*/}
                                <li><Link className="dropdown-item text-center pt-0" to="#" onClick={doSignOut}>SIGN OUT</Link></li>
                            </ul>
                        </div>
                    </div>

                </div>
            </nav>





















            <div className="modal fade" id="myModal2" tabIndex="-1" role="dialog" aria-labelledby="checkHead">
                <div className="modal-dialog modal-dialog-slideout" role="document">
                    <div className="modal-content">

                        <div className="modal-header border-0">
                            <h3 className="modal-title" id="checkHead">My Shopping Cart</h3>
                            <Link to="#" className="close" data-bs-dismiss="modal" aria-label="Close" style={{ textDecoration: "none", fontSize: "24px" }}><span aria-hidden="true">&times;</span></Link>
                        </div>

                        <div className="modal-body">

                            {(!loaded &&
                                <div className='text-center' id="cartDiv">
                                    <img src={emptyCartImg} className="img-fluid pt-3" alt="..." />
                                    <p className='pt-3'>Your cart is empty!</p>
                                </div>
                            )

                                ||

                                <>

                                    {product.map((theData, index) => (

                                        <div className='row' key={index}>
                                            <div className='col-sm-4 pt-3'>
                                                <img src={mainRobo} alt='...' id='productImg' width='100%' />
                                            </div>

                                            <div className='col-sm-6 pt-3'>
                                                {(!loaded && <span>...</span>) || <strong><p id='sumHead'>{theData['name']}</p></strong>}

                                                {(!loaded && <span>...</span>) || <p id='sumAmount'>
                                                    Amount: {country !== "78" ? <>$ {theData['cost']}</> : <>GH¢ {theData['cost']}</>}
                                                </p>}

                                                <p id='sumAmount'>Quantity: <strong>{theData['quantity']}</strong></p>
                                            </div>

                                            {/*<div className='col-sm-3 pt-2'>
                                            <div className="number2">
                                                <span className="minus2" onClick={doMinus}>-</span>
                                                <input id="cartAddAdd" className='text-center' type="text" value={count - 1}/>
                                                <span className="plus2" onClick={doPlus}>+</span>
                                            </div>
                                        </div>*/}

                                            <div className='col-sm-2 pt-2'>
                                                <Link to="#" onClick={doDelete} style={{ textDecoration: "none", color: "#000", fontSize: "20px" }}><i className='fa fa-trash'></i></Link>
                                            </div>
                                        </div>

                                    ))}

                                    <hr />

                                    <div className='card' id="subTotalCardMain">
                                        <div className='card-body' id='subTotalCard'>
                                            <p id='normalText'>Sub Total: <span style={{ float: "right" }}>{country !== "78" ? <>$ {subTotal}.00</> : <>GH¢ {subTotal}.00</>}</span></p>
                                            <p id='normalText'>Shipping: <span style={{ float: "right" }}>{country !== "78" ? <>$ {shipping}.00</> : <>GH¢ {shipping}.00</>}</span></p>
                                            <h3 id='totalText'>Total: <span style={{ float: "right" }}>{country !== "78" ? <>$ {total}.00</> : <>GH¢ {total}.00</>}</span></h3>
                                        </div>
                                    </div>

                                    <div className='pt-4 text-center'>
                                        <Link to="#" onClick={goCheck} id='checkout-btn'>Check Out</Link>
                                    </div>
                                </>

                            }

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Navbar