import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import About from './pages/About';
import Checkout2 from './pages/Checkout2';
import Contact from './pages/Contact';
import Gblox from './pages/Gblox';
import Offline from './pages/Offline';
import Home from './pages/Home';
import MingoRobot from './pages/MingoRobot';
import ResetPass from './pages/ResetPass';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Support from './pages/Support';
import Account from './pages/My Account/Account';
import Orders from './pages/My Account/Orders';
import Whishlist from './pages/My Account/Whishlist';
import Vouchers from './pages/My Account/Vouchers';
import PrivateRoute from './mixins/PrivateRoute';

function App() {

  return (

    <Router>
      <Routes>

        <Route exact path='/offline' element={<Offline />} />
        <Route exact path='/' element={<Home />} />
        <Route exact path='/about' element={<About />} />
        <Route exact path='/contact' element={<Contact />} />
        <Route exact path='/support' element={<Support />} />
        <Route exact path='/mingo_robot' element={<MingoRobot />} />
        <Route exact path='/checkout' element={<PrivateRoute><Checkout2 /></PrivateRoute>} />
        <Route exact path='/signin' element={<PrivateRoute><SignIn /></PrivateRoute>} />
        <Route exact path='/signup' element={<PrivateRoute><SignUp /></PrivateRoute>} />
        <Route exact path='/resetpass' element={<PrivateRoute><ResetPass /></PrivateRoute>} />
        <Route exact path='/gblox' element={<Gblox />} />
        <Route exact path='/account' element={<PrivateRoute><Account /></PrivateRoute>} />
        <Route exact path='/orders' element={<PrivateRoute><Orders /></PrivateRoute>} />
        <Route exact path='/whishlist' element={<PrivateRoute><Whishlist /></PrivateRoute>} />
        <Route exact path='/vouchers' element={<PrivateRoute><Vouchers /></PrivateRoute>} />

      </Routes>
    </Router>

  );
}

export default App;
