
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import '../css/signin.css';
import Swal from 'sweetalert2';
import axios from "axios";
axios.defaults.withCredentials = true;



const SignIn = () => {


    const [theEmail, setTheEmail] = useState();


    //Redirect to main page if already signed in
    useEffect(() => {
        var token = localStorage.getItem("token");

        if(!token)
        {
            //Do Nothing
        }
        else
        {
            window.location.href = "/mingo_robot";
        }

    }, []);





    //Sign In Function - Check Email
    const doSignIn = () => {

            var emailField = document.getElementById("userEmail").value;
        
            if (emailField === "") {
                Swal.fire({
                    toast: true,
                    position: "top-right",
                    showConfirmButton: false,
                    timer: 5000,
                    icon: "info",
                    title: "Ooops!!",
                    text: "The Email Field is Empty!",
                })
            }
            else {

                var userEmail = {
                    "email": emailField //document.getElementById("userEmail").value;
                }

                document.getElementById("loaderIcon").style.display = "block";
                
                axios
                .post("https://gblox.herokuapp.com/gblox/auth/checkemail", userEmail)
                .then((response) => {
                    if (response.data === false) {
                        localStorage.setItem("userEmail", emailField);

                        window.location.href = '/signup';
                        
                        document.getElementById("loaderIcon").style.display = "none";
                    }
                    else
                    {
                        setTheEmail(emailField);
                        document.getElementById("welcomeSection").style.display = "block";
                        document.getElementById("firstDiv").style.display = "none";
                    }
                })
                .catch((error) => {
                    if(error.response.status !== 200)
                    {
                        Swal.fire({
                            toast: true,
                            position: "top-right",
                            showConfirmButton: false,
                            timer: 5000,
                            icon: "error",
                            title: "Ooops!!",
                            text: error.response.data.error,
                        })
                    }
                });

            }

    }



    //Sign In Function - Check Email and Pass
    const checkMailPass = () => {

        var emailField = document.getElementById("theEmail").value;
        var passField = document.getElementById("thePass").value;

        if (emailField === "" || passField === "") {
            Swal.fire({
                toast: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 5000,
                icon: "info",
                title: "Ooops!!",
                text: "Please fill out all fields!",
            })
        }
        else {

            document.getElementById("loaderIcon2").style.display = "block";

            var userMailPass = {
                "email": emailField, 
                "password": passField 
            };

            

            axios.post(
                'https://gblox.herokuapp.com/gblox/auth/signin', userMailPass
            )
            .then(response => {

                var userData = JSON.stringify(response.data.payload);
                localStorage.setItem("userData", userData);

                var tkn = response.data.token;
                localStorage.setItem("token", tkn);

                Swal.fire({
                    toast: true,
                    position: "top-right",
                    showConfirmButton: false,
                    timer: 5000,
                    icon: "success",
                    title: "Success!!",
                    text: "Signed In",
                }).then(
                    setTimeout(function(){
                        window.location.href = '/mingo_robot';
                    }, 1000)
                );
                
            })
            .catch((error) => {
                if(error.response.status !== 200) {
                    
                    Swal.fire({
                        toast: true,
                        position: "top-right",
                        showConfirmButton: false,
                        timer: 5000,
                        icon: "error",
                        title: "Ooops!!",
                        text: error.response.data.error,
                    })

                    document.getElementById("loaderIcon2").style.display = "none";
                }
            });

            
        }

    }




    



  return (
    
    <>
        <Navbar />



        <div style={{overflow: "hidden"}}>
        

        <div id="firstDiv">
            <div className='container text-center' id="textHolder">
                <h3 className='pt-4' id='signHead'>What's your email address?</h3>

                <p id="signSub">Type your email to log in or create a Mingo Blox  account.</p>
            </div>

            <div className='container text-center mt-5' id="formsContainer">
                <div className='text-center' id="loaderIcon" style={{display: "none"}}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>

                <div className="form-group pt-4">
                    <input type="email" className="form-control" id="userEmail" placeholder="E-Mail" required />
                </div>

                <div className="form-group pt-3 mt-4">
                    <Link to="#" id='signIn-btn' onClick={doSignIn}>Continue</Link>
                </div>
            </div>
        </div>






    
        {/* Welcome Back */}
        <div id="welcomeSection" style={{display: "none"}}>
            <div className='container text-center' id="textHolder">
                <h3 id='signHead'>Welcome Back</h3>

                <p id="signSub">Sign in to your account and start shopping.</p>
            </div>

            <div className='container text-center mt-5' id="welcomeDiv">
                <div className='text-center' id="loaderIcon2" style={{display: "none"}}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>

                <div className="form-group pt-3">
                    <input type="email" className="form-control" id="theEmail" defaultValue={theEmail} placeholder="Email" required />
                </div>

                <div className="form-group pt-3">
                    <input type="password" className="form-control" id="thePass" placeholder="Password" required />
                </div>

                <p className='pt-3'>Forgot Password? <strong><Link to="/resetpass" style={{color: "#6666ea", textDecoration: "none"}}>Reset Password</Link></strong></p>

                <div className="form-group pt-3 mt-4">
                    <Link to="#" id='signIn-btn' onClick={checkMailPass}>Continue</Link>
                </div>
            </div>
        </div>







        {/* Verification Code */}
        <div id="verifySection" style={{display: "none"}}>
            <div className='container text-center' id="textHolder">
                <h3 className='pt-4' id='signHead'>Enter Verification Code</h3>

                <p id="signSub">A verification code has been sent to your Email. Please enter it below to proceed</p>
            </div>

            <div className='container text-center mt-5' id="formsContainer">
                <div className="form-group pt-3">
                    <input type="text" className="form-control" id="theFName" placeholder="Verification Code" required />
                </div>

                <div className="form-group pt-3 mt-4">
                    <Link to="#" id='signIn-btn'>Continue</Link>
                </div>
            </div>
        </div>



        <div className='container text-center' id="footHolder">
            <p id="footText">
                For further support, you may visit the <strong>Mingo Help Center</strong> or contact our
                customer service team.
            </p>
        </div>




        </div>
































        
        
    </>

  )

}

export default SignIn