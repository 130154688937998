import React, { useCallback, useEffect, useState } from "react";
import "../css/purchase.css";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import boxContent from "../images/parts_mingo.png";
import graphicImg from "../images/graphicImg.png";
import adruinoImg from "../images/adruinoImg.png";
import bluetoothImg from "../images/bluetoothImg.png";
import infraImg from "../images/infraImg.png";
import tankImg from "../images/tankImg.png";
import triImg from "../images/triImg.png";
import grabImg from "../images/grabImg.png";
import coding_img from "../images/coding_img.png";
import elecImg from "../images/elecImg.png";
import roboImg from "../images/roboImg.png";
import stemmImage from "../images/stemmImage.png";
import lightImg from "../images/lightImg.png";
import obstacleImg from "../images/obstacleImg.png";
import lineImg from "../images/lineImg.png";
import rgbImg from "../images/rgbImg.png";
import Testimonials from "../components/Testimonials";
import Footer from "../components/Footer";
import mainRobo from "../images/mainRobo.png";
import img1 from "../images/img-1.png";
import img2 from "../images/img-2.png";
import img3 from "../images/img-3.png";
import img4 from "../images/img-4.png";
import Swal from "sweetalert2";
import ImageViewer from "react-simple-image-viewer";
import contactImg from "../images/image-contact.png";

const MingoRobot = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isClicked, setIsClicked] = useState(false);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [country, setCountry] = useState("78");
  let [itemQty, setItemQty] = useState(1);


  //Check Country on page load






  const images = [img1, img2, img3, img4];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  //Increase item quantity
  /*const doPlus = () => {
    itemQty = itemQty + 1;
    setItemQty(itemQty);
  };

  //Decrease item quantity
  const doMinus = () => {
    if (itemQty <= 1) {
      setItemQty(1);
    } else {
      itemQty = itemQty - 1;
      setItemQty(itemQty);
    }
  };

  //Add to cart
  const addToCart = () => {

    var productInfo = JSON.stringify([
      {
        _id: "62dea0cf3e8e0e4730396bf4",
        name: "Educational Robot",
        description: "This is a description",
        cost: country !== "78" ? 200.0 : 2000.0,
        quantity: itemQty,
      },
    ]);

    //console.log(JSON.parse(productInfo));

    localStorage.setItem("productInfo", productInfo);

    Swal.fire({
      toast: true,
      position: "top-right",
      showConfirmButton: false,
      timer: 2000,
      icon: "success",
      title: "Success!!",
      text: "Item Added To Cart",
    }).then(
      setTimeout(function () {
        window.location.reload();
      }, 2000)
    );

  };
  */

  //read more
  const doReadMore = () => {
    document.getElementById("readMoreBtn").style.display = "none";
    document.getElementById("readLessBtn").style.display = "block";
    document.getElementById("shortDesc2").style.display = "block";
  };

  //read less
  const doReadLess = () => {
    document.getElementById("readMoreBtn").style.display = "block";
    document.getElementById("readLessBtn").style.display = "none";
    document.getElementById("shortDesc2").style.display = "none";
  };

  const doTick = () => {
    if (isClicked === true) {
      setIsClicked(false);
      Swal.fire({
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
        icon: "info",
        title: "Removed from wishlist!",
      });
    } else {
      setIsClicked(true);
      Swal.fire({
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
        icon: "success",
        title: "Added to wishlist!",
      });
    }
  };









  return (
    <>
      <Navbar />

      {/* First Section */}
      <div className="container mb-5" style={{ marginTop: "6rem" }}>

        <div className="row">
          {/* Product Images */}
          <div className="col-md-6 pt-3">
            <div className="product-showcase-gallery">
              <div className="row">
                <div className="col-md-12">
                  <div className="big-image">
                    <img className="img-fluid" src={mainRobo} alt="..." />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="small-images">
                    {images.map((src, index) => (
                      <img
                        key={index}
                        src={src}
                        onClick={() => openImageViewer(index)}
                        className="img-fluid"
                        alt="..."
                      />
                    ))}
                  </div>
                </div>
              </div>

              {isViewerOpen && (
                <ImageViewer
                  src={images}
                  currentIndex={currentImage}
                  disableScroll={false}
                  closeOnClickOutside={true}
                  onClose={closeImageViewer}
                />
              )}
            </div>
          </div>

          {/* Product Details */}
          <div className="col-md-6 pt-3">
            <h3 id="sec1Head">Mingo</h3>

            <div className="pt-1" id="theStars">
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star"></span>
              <span style={{ color: "#989898" }}> Ratings </span>
            </div>

            <div className="pt-3">
              <h3 id="thePrice">
                {" "}
                {
                  country !== "78" ?
                    <>
                      $ 200.00 <small id="smallPrice"> $ 250.00 </small>
                    </>
                    :
                    <>
                      GH¢ 2000.00 <small id="smallPrice"> GH¢ 2500.00 </small>
                    </>
                }
              </h3>
            </div>

            <hr />

            <div className="pt-1">
              <p id="shortDesc">
                Mingo is a programmable educational robot kit for learners aged
                8years +. It includes mechanical parts, and electronic modules
                that can be assembled onto the robot to function and react to
                its environment, as well as programming software and course
                materials. Learners will develop the ability to build the robot
                from scratch and experience the fun of hands on creation whiles
                learning how different mechanical structures or parts and electronic modules function.
              </p>
              <Link
                to="#"
                id="readMoreBtn"
                onClick={doReadMore}
                style={{ textDecoration: "none" }}
              >
                read more...
              </Link>

              <p id="shortDesc2" style={{ display: "none" }}>
                With a fun and interactive graphical software, Mingo helps
                learners develop their skills and knowledge in STEAM (Science,
                Technology, Engineering, Art and Mathematics).
              </p>
              <Link
                to="#"
                id="readLessBtn"
                onClick={doReadLess}
                style={{ textDecoration: "none", display: "none" }}
              >
                ...read less
              </Link>
            </div>

            <hr />

            <div className="pt-4 mb-2">
              <Link to="#" id="addToCart2" data-bs-toggle="modal" data-bs-target="#howToModal">
                How To Purchase
              </Link>
            </div>

            {/*<div className="row pt-4 mb-2">
              <div className="col-sm-4">
                <div className="number2">
                  <Link
                    className="minus2"
                    id="minusBtn"
                    to="#"
                    onClick={doMinus}
                  >
                    -
                  </Link>
                  <input
                    id="cartAddAdd"
                    min={1}
                    className="text-center"
                    type="number"
                    value={itemQty}
                    onChange={(e) => setItemQty(e.target.value)}
                    autoComplete="off"
                  />
                  <Link className="plus2" to="#" onClick={doPlus}>
                    +
                  </Link>
                </div>
              </div>

              <div className="col-sm-6 pt-2">
                <Link to="#" id="addToCart" onClick={addToCart}>
                  Add to cart
                </Link>
              </div>

              <div className="col-sm-2 pt-2">
                <Link
                  className={isClicked === true ? "active" : ""}
                  to="#"
                  id="addToWishlist"
                  onClick={doTick}
                >
                  <i className="fa fa-heart" id="wishlistIcon"></i>
                </Link>
              </div>
            </div>*/}

            {/*}
                    <div className='pt-5' id="shareSocial">
                        <span style={{fontWeight: "bold"}}> Share: </span>

                        <div className='pt-4'>
                            <SocialIcons />
                        </div>

                    </div>*/}
          </div>

        </div>
      </div>

      {/* Video Section */}
      <div className="container-fluid pt-5" id="botBanner"></div>

      {/* Whats in the Box Section */}
      <div
        className="container-fluid pt-5 pb-5"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="container pt-3">
          <div className="row">
            <div className="col-md-6 pt-3">
              <img src={boxContent} alt="..." width="100%" />
            </div>

            <div className="col-md-6 pt-4">
              <h3 className="pt-3" id="boxHead">
                What's in the box
              </h3>

              <div className="row">
                <div className="col-md-6">
                  <ul className="pt-4" id="theList">
                    <li>Mingo chasis</li>
                    <li>DC Motors</li>
                    <li>Robot wheels</li>
                    <li>Castor wheels</li>
                    <li>Robot tracks</li>
                    <li>IR Remote controller</li>
                    <li>Screw driver</li>
                    <li>GoCore controller</li>
                    <li>Servo motor</li>
                  </ul>
                </div>

                <div className="col-md-6">
                  <ul className="pt-4" id="theList">
                    <li>Grabber</li>
                    <li>4x AA Battery pack</li>
                    <li>Track guide</li>
                    <li>Track gears</li>
                    <li>USB serial cable</li>
                    <li>Ultrasonic sensor</li>
                    <li>Light sensor</li>
                    <li>Line sensor</li>
                    <li>Screws and Nuts</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Amazing Section */}
      <div className="container pt-5 pb-5">
        <h3 id="amazingHead">Mingo's Amazing Features</h3>

        <div className="row pt-5">
          <div className="text-center col-md-3 pt-3">
            <img src={graphicImg} alt="..." />

            <p className="pt-5" id="lowerText">
              Graphical Programming
            </p>
          </div>

          <div className="text-center col-md-3 pt-3">
            <img src={adruinoImg} alt="..." />

            <p className="pt-5" id="lowerText">
              Arduino compatible
            </p>
          </div>

          <div className="text-center col-md-3 pt-3">
            <img src={infraImg} alt="..." />

            <p className="pt-5" id="lowerText">
              Interactive Remote Control
            </p>
          </div>

          <div className="text-center col-md-3 pt-3">
            <img src={bluetoothImg} alt="..." />

            <p className="pt-5" id="lowerText">
              Bluetooth Communication
            </p>
          </div>
        </div>
      </div>

      {/* Different Forms Section */}
      <div className="container-fluid pt-5 pb-5" id="difFormsBg">
        <div className="container pt-5 pb-5">
          <h3 className="text-center" id="difHead">
            Same buddy, different builds,  unlimited <br /> possibilities
          </h3>

          <p className="text-center pt-4" id="difSub">
            Unlock more fun with awesome add-on Packs
          </p>

          <div className="row pt-4 text-center">
            <div className="col-md-4 pt-3">
              <div className="card" id="sameCardMain">
                <div className="card-body" id="sameCard">
                  <img
                    className="img-fluid"
                    src={tankImg}
                    alt="..."
                    width="70%"
                  />
                </div>

                <p className="text-center pt-4" id="sameText">
                  Tank Wheeler
                </p>
              </div>
            </div>

            <div className="col-md-4 pt-3">
              <div className="card" id="sameCardMain">
                <div className="card-body" id="sameCard2">
                  <img
                    className="img-fluid"
                    src={triImg}
                    alt="..."
                    width="70%"
                  />
                </div>

                <p className="text-center pt-4" id="sameText">
                  Tri-Wheeler
                </p>
              </div>
            </div>

            <div className="col-md-4 pt-3">
              <div className="card" id="sameCardMain">
                <div className="card-body" id="sameCard3">
                  <img
                    className="img-fluid"
                    src={grabImg}
                    alt="..."
                    width="75%"
                  />
                </div>

                <p className="text-center pt-4" id="sameText">
                  Grabber
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Built With Section */}
      <div
        className="container-fluid pt-5 pb-5"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="container pt-4">
          <h3 className="text-center" id="builtHead">
            Built with the best of Advanced <br />
            Technology
          </h3>

          {/*<p className="text-center pt-3" id="builtSub">
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
            officia in deserunt mollit anim <br /> id est sint laborum.
          </p>*/}

          <div className="row pt-5">
            <div className="col-md-3 pt-2">
              <img
                src={lightImg}
                alt="..."
                style={{ width: "100%", borderRadius: "20px" }}
              />

              <p className="pt-3" id="dHead">
                Light Detection
              </p>
              <p id="buitSub">
                Your robot is smart enough to move towards a light source by
                programming it to do exactly that and more!
              </p>
            </div>

            <div className="col-md-3 pt-2">
              <img
                src={obstacleImg}
                alt="..."
                style={{ width: "100%", borderRadius: "20px" }}
              />

              <p className="pt-3" id="dHead">
                Obstacle Detection
              </p>
              <p id="buitSub">
                You can program your robot to detect obstacles and avoid them
              </p>
            </div>

            <div className="col-md-3 pt-2">
              <img
                src={lineImg}
                alt="..."
                style={{ width: "100%", borderRadius: "20px" }}
              />

              <p className="pt-3" id="dHead">
                Path finder
              </p>
              <p id="buitSub">
                Get Mingo to find its own way. Program your robot to detect and
                navigate path
              </p>
            </div>

            <div className="col-md-3 pt-2">
              <img
                src={rgbImg}
                alt="..."
                style={{ width: "100%", borderRadius: "20px" }}
              />

              <p className="pt-3" id="dHead">
                Interactive LED light
              </p>
              <p id="buitSub">
                Spark up the light in your world with Mingo’s unlimited range of
                colourful lights.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Mingo for Coding Section */}
      <div className="container pt-5 pb-5">
        <div className="row">
          <div className="col-md-6 pt-2">
            <h3 className="pt-5" id="mingoHead">
              Mingo for Coding
            </h3>

            <p className="pt-3" id="mingoSub">
              A free application provides free instructions for starting,
              configuring and programming your robot.
            </p>
          </div>

          <div className="col-md-6 pt-2 text-center">
            <img src={coding_img} alt="..." width="80%" />
          </div>
        </div>
      </div>

      {/* Mingo for Electronics Section */}
      <div
        className="container-fluid pt-5 pb-5"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 pt-2 text-center">
              <img src={elecImg} alt="..." width="80%" />
            </div>

            <div className="col-md-6 pt-2">
              <h3 className="pt-5" id="mingoHead">
                Mingo for Electronics
              </h3>

              <p className="pt-3" id="mingoSub">
                A free application provides free instructions for starting,
                configuring and programming your robot.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Mingo for Robotics Section */}
      <div className="container pt-5 pb-5">
        <div className="row">
          <div className="col-md-6 pt-2">
            <h3 className="pt-5" id="mingoHead">
              Mingo for Robotics
            </h3>

            <p className="pt-3" id="mingoSub">
              Build their own mingo robots from scratch, and with the combination of the power of coding, they are able to unlock their knowledge and unleashing their inner engineering Prowess.
            </p>
          </div>

          <div className="col-md-6 pt-2 text-center">
            <img src={roboImg} alt="..." width="80%" />
          </div>
        </div>
      </div>

      {/* Mingo for STEM Section */}
      <div
        className="container-fluid pt-5 pb-5"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 pt-2 text-center">
              <img src={stemmImage} alt="..." width="80%" />
            </div>

            <div className="col-md-6 pt-2">
              <h3 className="pt-5" id="mingoHead">
                Mingo for STEM
              </h3>

              <p className="pt-3" id="mingoSub">
                By engaging in creativity, arts, play, do-it-yourself projects with mingo, learners gain the skills, knowledge, confidence and self-efficacy necessary to begin a successful career in STEM.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Testimonials />

      {/* Join 5000 section */}
      <div
        className="container-fluid pt-5"
        style={{ backgroundColor: "#6666EA" }}
      >
        <div className="container pt-3 pb-3">
          <div className="row pb-5">
            <div className="col-md-6">
              <h3 id="joinHead">
                Join 5,000+ learners <br /> from across the Globe
              </h3>
            </div>

            <div className="col-md-6 pt-4 text-center">
              <Link to="#" id="dSignUp">
                Sign Up
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Footer />













      {/* Purchase Modal */}
      <div className="modal fade" id="howToModal" tabIndex="-1" aria-labelledby="howToModal" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered" id="theHowToModal">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="container">
                <div className="row mb-5">
                  <div className="text-center col-md-5 pt-3 pb-3">
                    <img src={contactImg} alt="..." width="250px" />
                  </div>

                  <div className="col-md-7">
                    <h4 id="thankYou">Thank You</h4>

                    <p style={{ fontSize: "22px" }}>For purchasing our product</p>
                    <br />

                    <p className="text-secondary">
                      To proceed with payment, please contact our <br />
                      customer care centre on:
                    </p>

                    <p id="dMoNumber">+233 (0) 24 444 6002</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default MingoRobot;
