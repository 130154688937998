import React from 'react';
import '../css/gBlox.css';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import playstoreImg from '../images/playstore.png';
import applstoreImg from '../images/applestore.png';
import lapeeImg from '../images/lapeeImg.png';
import gMobImg from '../images/gMobImg.png';
import controlImg from '../images/control.png';
import goCore from '../images/GoCore.png';
import adruino from '../images/adruino.png';
import lapee2 from '../images/lapee2.png';
import roboCard from '../images/roboCard.png';

const Gblox = () => {

  return (

    <>

        <Navbar />


        {/* GBlox Section Main */}
        <div className='container-fluid pt-5 pb-5' id="gbloxBackM">
          <div className='container pt-5 pb-5'>

                <h2 className='text-white pt-5' id='servicesHeadM' style={{fontWeight: "bold"}}>
                  gBlox Desktop & Mobile
                </h2>

                <p className='pt-4 text-white' id='servicesTextM'>
                    A grahpical programing software for learning <br /> Coding, Robotics and STEM
                </p>

                <p className='pt-4 pb-3 text-white'>
                  An intuitive coding platform using drag and drop. Advance <br /> users can program their robot using text-based <br /> environment
                </p>

                <a href="https://drive.google.com/uc?export=download&id=1cf_uqe7x4Im0vWSh1E45V6i-x2sLXcx5&confirm=t&uuid=89bed83b-31a4-47c3-b564-5917554930d2" rel="noreferrer"  id='explore-btn'>
                    Download Desktop App
                </a>

                <div className='pt-5'>
                  <a href="https://play.google.com/store/apps/details?id=com.mingoblox.gblox_mobile" rel="noreferrer" target="_blank"><img src={playstoreImg} alt='playstore' id="downloadIcons" /></a>
                  <Link to="#"><img className='mx-4' src={applstoreImg} alt='applestore' id="downloadIcons" /></Link>
                </div>
          </div>
        </div>




        {/* Explore section */}
        <div className='container-fluid pt-5 pb-3' id="expMainBack">
            <h3 className='text-center text-white pt-4 pb-5' id="expHead">Explore the world of Coding <br /> with gblox App</h3>

            {/* Section One */}
            <div className='container pt-5' id='secOne'>
                <div className='row pt-5' id="secOneRow">

                    <div className='col-md-6 pt-3'>
                        <h4 id="secOneHead">Learn coding with <br /> ease while having fun</h4>

                        <p className='pt-5' id='secOneSub'>
                            Learning coding can be fun and exiting <br /> with the easy. With the easy drag-and- <br /> drop aproach, gblox makes for kids and <br /> beginners. 
                        </p>
                    </div>

                    <div className='col-md-6 pt-3 text-center'>
                        <img className='img-fluid' src={lapeeImg} alt='...' width='70%' />
                    </div>

                </div>
            </div>
        </div>



        {/* Section Two */}
        <div className='container-fluid pb-3' style={{backgroundColor: "#0D0F45"}}>

            <div className='container pt-5' id='secTwo'>
                <div className='row pt-5' id="secOneRow">

                    <div className='col-md-6 text-center'>
                        <img className='img-fluid' src={gMobImg} alt='...' width='70%' />
                    </div>

                    <div className='col-md-6 pt-3'>
                        <h4 id="secOneHead">Coding on the go</h4>

                        <p className='pt-5' id='secOneSub'>
                            With gblox mobile app quisquam est, <br /> qui dolorem ipsum quia dolor sit amet, <br /> 
                            consectetur, adipisci velit, sed quia non <br /> umquam eius modi tempora incidunt <br /> ut labore et 
                        </p>
                    </div>

                </div>
            </div>
        
        </div>



        {/* Section Three */}
        <div className='container-fluid' style={{backgroundColor: "#0D0F45"}}>

            <div className='container pt-5' id='secOne'>
                <div className='row pt-5' id="secOneRow">

                    <div className='col-md-6'>
                        <h4 id="secOneHead">Control your Robot <br /> with the Easy-to-use <br /> gblox mobile App</h4>

                        <p className='pt-4' id='secOneSub'>
                            Learning coding can be fun and exiting <br /> with the easy. With the easy drag-and- <br /> drop aproach, gblox makes for kids and <br /> beginners. 
                        </p>
                    </div>

                    <div className='col-md-6 pt-1 text-center'>
                        <img className='img-fluid' src={controlImg} alt='...' width='70%' />
                    </div>

                </div>
            </div>

        </div>




        {/* Compatible Section */}
        <div className='container-fluid' style={{backgroundColor: "#110C37"}}>
            <div className='container pt-5 pb-4'>
                <div className='row pt-4'>

                    <div className='col-md-6 pt-3'>
                        <h4 id="compHead">Compatible with your favorite <br /> Hardware</h4>
                    </div>

                    <div className='col-md-3 pt-2 text-center '>
                        <img className='img-fluid' src={goCore} alt='...' />

                        <p className='text-white pt-2'>GoCore</p>  
                    </div>

                    <div className='col-md-3 pt-2 text-center '>
                        <img className='img-fluid' src={adruino} alt='...' />

                        <p className='text-white pt-2'>Arduino</p> 
                    </div>

                </div>
            </div>
        </div>





        {/* Start Coding Journey Section */}
        <div className='container-fluid' style={{backgroundColor: "#0000DC"}}>
            <div className='container pt-5 pb-5'>
                <div className='row pt-4 pb-4'>

                    <div className='col-md-6 pt-5'>
                        <img className='img-fluid' src={lapee2} alt='...' />
                    </div>

                    <div className='col-md-6 pt-3'>
                        <h4 id="startHead">
                            Start your Coding journey! <br />
                            Get your perfect tool to <br /> explore Coding, Electronics, <br /> Robotics and STEAM
                        </h4>

                        <br />

                        <a href="https://drive.google.com/uc?export=download&id=1cf_uqe7x4Im0vWSh1E45V6i-x2sLXcx5&confirm=t&uuid=89bed83b-31a4-47c3-b564-5917554930d2" rel="noreferrer"  id='explore-btn'>
                            Download Desktop App
                        </a>

                        <div className='pt-5'>
                            <a href="https://play.google.com/store/apps/details?id=com.mingoblox.gblox_mobile" rel="noreferrer" target="_blank"><img src={playstoreImg} alt='playstore' id="downloadIcons" /></a>
                            <Link to="#"><img className='mx-4' src={applstoreImg} alt='applestore' id="downloadIcons" /></Link>
                        </div>
                    </div>

                </div>
            </div>
        </div>







        {/* Featured Section */}
        <div className='container-fluid pb-5' style={{backgroundColor: "#0D0F45"}}>
            <div className='container pt-5 pb-4'>
                <h3 className='text-center text-white pt-4 pb-5' id="expHead">Featured Product</h3>

                <div className='card pt-5' id="featCard">
                    <div className='card-body'>
                        <div className='row'>

                            <div className='col-md-6'>
                                <img className='img-fluid' src={roboCard} alt='...' />
                            </div>

                            <div className='col-md-6 pt-5'>
                                <h3 className='text-white' id="expHead">Mingo</h3>

                                <p className='text-white pt-3' id='featSub'>
                                    Start your learning journey! <br />
                                    Get your perfect tool to <br /> explore Coding, Electronics, <br /> Robotics and STEAM
                                </p>

                                <div className='pt-3'>
                                    <a href='/mingo_robot' id='buyMingo'>Buy Mingo</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>









        <Footer />


    </>

  )

}

export default Gblox