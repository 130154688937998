import React from 'react';
import studentsImg from '../images/students.png';
import ratioImg from '../images/ratioImg.png';
import yearsImg from '../images/yearsImg.png';
import parentImg from '../images/parentImg.png';

const NumbersCount = () => {

  return (
    <>
        <div className='container-fluid pt-5 pb-5' style={{backgroundColor: "#0000dc"}}>
          <div className='container pt-4 pb-4'>

            <div className='row'>
                <div className='col-md-3 text-center' id="counterDiv">
                    <img src={studentsImg} alt='students' width="40%" />

                    <h3 className='text-white pt-4' style={{fontSize: "40px", fontWeight: "bold"}}>5,000+</h3>

                    <small className='text-white pt-3'>Students Trained</small>
                </div>

                <div className='col-md-3 text-center' id="counterDiv">
                    <img src={ratioImg} alt='students' width="40%" />

                    <h3 className='text-white pt-4' style={{fontSize: "40px", fontWeight: "bold"}}>10:1</h3>

                    <small className='text-white pt-3'>Student - Teacher Ratio</small>
                </div>

                <div className='col-md-3 text-center' id="counterDiv">
                    <img src={yearsImg} alt='students' width="40%" />

                    <h3 className='text-white pt-4' style={{fontSize: "40px", fontWeight: "bold"}}>13+</h3>

                    <small className='text-white pt-3'>Years Teaching Robotics To Students</small>
                </div>

                <div className='col-md-3 text-center' id="counterDiv">
                    <img src={parentImg} alt='students' width="40%" />

                    <h3 className='text-white pt-4' style={{fontSize: "40px", fontWeight: "bold"}}>100%</h3>

                    <small className='text-white pt-3'>Learner and Parent Satisfaction</small>
                </div>
            </div>
            
          </div>
        </div>
    </>
  )

}

export default NumbersCount