import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import '../css/signin.css';
import Swal from 'sweetalert2';
import axios from "axios";
axios.defaults.withCredentials = true;





const ResetPass = () => {

    const [cookie, setCookie] = useState();
    const [email, setEmail] = useState();


    //Forgot Password function
    const forgotPass = (e) => {
        e.preventDefault();

        var theEmail = document.getElementById('dEmail').value;

        if (theEmail === "") {
        Swal.fire({
            toast: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 5000,
            icon: "warning",
            title: "Ooops!",
            text: "The Email field is empty!!",
        });
        } else {

            setEmail(theEmail);
            
        document.getElementById("loaderIcon1").style.display = "block";

        var data = JSON.stringify({
            email: theEmail,
        });

        var config = {
            method: "post",
            url: "https://gblox.herokuapp.com/gblox/auth/frgetpass",
            headers: {
            "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {

            var thecookie = response.data.token;
            setCookie(thecookie);

            document.getElementById("enterMailSection").style.display = "none";
            document.getElementById("enterCodeSection").style.display = "block";

            })
            .catch(function (error) {
                console.log(error);

                Swal.fire({
                    toast: true,
                    position: "top-right",
                    showConfirmButton: false,
                    timer: 5000,
                    icon: "error",
                    title: "Ooops!!",
                    text: error.response.data.error,
                });
            });
        }
    };


    //When Token is Entered
  const sendCode = (e) => {
    e.preventDefault();

    var code = document.getElementById('dCode').value;

    if (code === "") {
      Swal.fire({
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        timer: 5000,
        icon: "warning",
        text: "Please enter the 6-Digit code we sent to your email!",
      });
    } else {
      document.getElementById("loaderIcon2").style.display = "block";


      var data = JSON.stringify({
        verifytoken: code,
      });

      var config = {
        method: "post",
        url: "https://gblox.herokuapp.com/gblox/auth/validate",
        headers: {
          "x-access-token": cookie,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {

          console.log(response)

          document.getElementById("enterCodeSection").style.display = "none";
          document.getElementById("enterPassSection").style.display = "block";
        })
        .catch(function (error) {
          console.log(error);

          Swal.fire({
            toast: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 5000,
            icon: "error",
            title: "Ooops!!",
            text: error.response.data.error,
          });
        });
    }
  };




  //Set new password
  const updatePassword = (e) => {
    e.preventDefault();

    var newPass = document.getElementById('thePass').value;
    var newCPass = document.getElementById('theConPass').value;

    if (newPass === "") {
      Swal.fire({
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        timer: 5000,
        icon: "warning",
        text: "Please enter your new password!",
      });
    } 
    else if (newPass !== newCPass)
    {
        Swal.fire({
            toast: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 5000,
            icon: "warning",
            text: "Passwords do not match!",
          });
    }
    else {
      document.getElementById("loaderIcon3").style.display = "block";

      var data = JSON.stringify({
        email: email,
        password: newPass
      });

      var config = {
        method: "put",
        url: "https://gblox.herokuapp.com/gblox/auth/reset",
        headers: {
          "x-access-token": cookie,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {

          Swal.fire({
            toast: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 5000,
            icon: "success",
            title: "Success!!",
            text: "Password has been changed. You can now login with your new password.",
          }).then(() => {
            setTimeout(() => {
              window.location.href = "/signin";
            }, 1000);
          });
        })
        .catch(function (error) {
          console.log(error);

          Swal.fire({
            toast: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 5000,
            icon: "error",
            title: "Ooops!!",
            text: error.response.data.error,
          });
        });
    }
  };








  return (
    
    <>
        <Navbar />


        <div id="enterMailSection">
            <div className='container text-center pt-4' id="textHolder">
                <h3 id='signHead'>Keep Calm!!</h3>

                <p id="signSub">Enter your email and we will help you reset your password</p>
            </div>

            <div className='container text-center mt-5' id="welcomeDiv">
                <div className='text-center' id="loaderIcon1" style={{display: "none"}}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>

                <div class="form-group pt-3">
                    <input type="email" class="form-control" id="dEmail" placeholder="Email Address" required />
                </div>

                <div class="form-group pt-3 mt-4">
                    <Link to="#" id='signIn-btn' onClick={forgotPass}>Submit</Link>
                </div>
            </div>
        </div>




        <div id="enterCodeSection" style={{display: "none"}}>
            <div className='container text-center pt-4' id="textHolder">
                <h3 id='signHead'>Final Step</h3>

                <p id="signSub">Enter the verification code sent to your email and click continue</p>
            </div>

            <div className='container text-center mt-5' id="welcomeDiv">
                <div className='text-center' id="loaderIcon2" style={{display: "none"}}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>

                <div class="form-group pt-3">
                    <input type="text" class="form-control" id="dCode" placeholder="Verification Code" autoComplete='off' />
                </div>

                <div class="form-group pt-3 mt-4">
                    <Link to="#" id='signIn-btn' onClick={sendCode}>Continue</Link>
                </div>
            </div>
        </div>




        <div id="enterPassSection" style={{display: "none"}}>
            <div className='container text-center pt-4' id="textHolder">
                <h3 id='signHead'>Your Account is Safe</h3>

                <p id="signSub">Type in your new password and confirm to access your account</p>
            </div>

            <div className='container text-center mt-5' id="welcomeDiv">
                <div className='text-center' id="loaderIcon3" style={{display: "none"}}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>

                <div class="form-group pt-3">
                    <input type="password" class="form-control" id="thePass" placeholder="New Password" required />
                </div>

                <div class="form-group pt-3">
                    <input type="password" class="form-control" id="theConPass" placeholder="Confirm New Password" required />
                </div>
                <div class="form-group pt-3 mt-4">
                    <Link to="#" id='signIn-btn' onClick={updatePassword}>Reset Password</Link>
                </div>
            </div>
        </div>


        <div className='container text-center' id="footHolder">
            <p id="footText">
                For further support, you may visit the <strong>Mingo Help Center</strong> or contact our
                customer service team.
            </p>
        </div>






    </>

  )

}

export default ResetPass