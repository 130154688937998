import React, { useCallback, useState } from 'react';
import '../css/style.css';
import { Link } from 'react-router-dom';
import Carousel from '../components/Carousel';
import Navbar from '../components/Navbar';
import Services from '../components/Services';
import Sponsors from '../components/Sponsors';
import gallery1 from '../images/gallery1.png';
import gallery2 from '../images/gallery2.png';
import gallery3 from '../images/gallery3.png';
import gallery4 from '../images/gallery4.png';
import gallery5 from '../images/gallery5.png';
import gallery6 from '../images/gallery6.png';
import peopleCello from '../images/peopleCello.png';
import celloImg from '../images/celloImg.png';
import peopleGroup from '../images/peopleGroup.png';
import robocentre_logo from '../images/robocentre_logo.png';
import playstoreImg from '../images/playstore.png';
import applstoreImg from '../images/applestore.png';
import NumbersCount from '../components/NumbersCount';
import Testimonials from '../components/Testimonials';
import Footer from '../components/Footer';
import 'animate.css';
import Youtube from '../components/Youtube';
import ImageViewer from 'react-simple-image-viewer';







const Home = () => {



  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);





  //Gallery Images
  const images = [
    gallery1,
    gallery2,
    gallery3,
    gallery4,
    gallery5,
    gallery6,
  ];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };




  return (
    <>
      <div>

        <Navbar />

        <Carousel />

        <Sponsors />

        <Services />

        <Youtube />


        {/* Excellent Section *
        <div className='container-fluid pt-5 pb-5' id="excellentContainer">
          <div className='container pt-3 pb-5'>

            <div className='row'>
              <div className='col-md-6 pt-5'>
                <img src={excellentImg} alt='excellent' width='100%' />
              </div>

              <div className='col-md-6 pt-5 text-white' id="excellentDiv">
                <h2 className='' id='servicesHead' style={{fontWeight: "bold"}}>
                  Mingo Robot
                </h2>

                <p className='pt-3 pb-5 text-white' id='servicesText'>
                  An educational tool to explore Basic to Advance Coding, <br /> Electronics and STEM
                </p>

                <Link to="#" id='explore-btn'>Buy Now</Link>
                <Link to="#" id='explore-btn5'>Explore more</Link>
              </div>
            </div>
          </div>
        </div>*/}





        {/* GBlox Section */}
        <div className='container-fluid pt-5 pb-5' id="gbloxBack">
          <div className='container pt-5 pb-5'>

            <h2 className='text-white pt-4' id='servicesHead' style={{ fontWeight: "bold" }}>
              gBlox Desktop & Mobile
            </h2>

            <p className='pt-4 pb-5 text-white' id='servicesText'>
              An intuitive coding platform using drag and drop. <br /> Advance users can program their robot using text-based <br /> environment
            </p>

            <Link to="/gblox" id='explore-btn'>Learn More</Link>

            <a className='mx-3' href="https://drive.google.com/uc?export=download&id=1cf_uqe7x4Im0vWSh1E45V6i-x2sLXcx5&confirm=t&uuid=89bed83b-31a4-47c3-b564-5917554930d2" rel="noreferrer" id='explore-btn8'>Download Desktop App</a>

            <div className='pt-5'>
              <a href="https://play.google.com/store/apps/details?id=com.mingoblox.gblox_mobile" rel="noreferrer" target="_blank"><img src={playstoreImg} alt='playstore' id="downloadIcons" /></a>
              <Link to="#"><img className='mx-4' src={applstoreImg} alt='applestore' id="downloadIcons" /></Link>
            </div>
          </div>
        </div>





        {/* Cello Section */}
        <div className='container-fluid pt-5' id="celloContainer">
          <div className='container pt-3 text-white'>
            <h2 className='text-center' id='servicesHead'>
              <strong>Your robot Co-worker</strong>
            </h2>

            <h3 className='text-center pt-3' style={{ fontWeight: "bold", color: "#ffc400" }}>Cello!</h3>

            <p className='pt-3 text-center text-white' id='servicesTSub'>
              Let Cello interact with your audience and bring great value to your business.
              Book Cello for your <br /> conference, tradeshow, retail store, activation and many more. <br />
              Let Cello pull crowd and attention for your business.
            </p>

            <div className='row pt-4'>
              <div className='col-md-6 text-center pt-3'>

                <img id='CelloBot' src={celloImg} alt='cello' style={{ height: "100%" }} />

              </div>

              <div className='col-md-6 text-center mt-0' id="peopleContainer">
                <img src={peopleCello} alt='people' width="100%" id="peopleCelloImg" /> <br /> <br />

                <Link to="#" id='meet-btn'>Book Cello</Link>
              </div>

            </div>

          </div>
        </div>





        {/* Well Structured Section 
        <div className='container-fluid pt-5' style={{backgroundColor: "#fff"}}>
          <div className='container pt-5'>

            <div className='row pb-4'>

              <div className='col-md-6'>
                <img src={wellCards} alt='gblox' width="100%" />
              </div>

              <div className='col-md-6 pt-5'>
                <h2 className='' id='servicesHead'>
                  <strong>Well Structured Curriculum System</strong>
                </h2>

                <p className='pt-4 pb-5' id='servicesText'>
                  Neque porro quisquam est, qui dolorem ipsum quia 
                  dolor sit amet, consectetur, adipisci velit, sed 
                  Neque porro quisquam est, qui dolorem ipsum quia 
                  dolor sit amet, consectetur, adipisci velit, sed 
                  quia non umquam eius modi tempora incidunt ut labore et 
                </p>

                <Link to="#" id='explore-btn'>Explore more</Link>

              </div>
            </div>
          </div>
        </div>


        <br />
        <br /> */}

        <NumbersCount />


        <Testimonials />


        {/* Gallery Section */}
        <div className='container-fluid pt-5' style={{ backgroundColor: "#fff" }}>
          <div className='container pt-5'>

            <div className='row pb-4 pswp-gallery' id='my-gallery'>

              <h2 className='text-center pb-4' id='servicesHead'>
                <strong>Our Gallery</strong>
              </h2>

              {images.map((src, index) => (
                <div className='col-md-4 pt-3'>
                  <img
                    src={src}
                    onClick={() => openImageViewer(index)}
                    id='galleryImg' alt='gblox' width="100%"
                    key={index}
                  />
                </div>
              ))}

              {isViewerOpen && (
                <ImageViewer
                  src={images}
                  currentIndex={currentImage}
                  disableScroll={false}
                  closeOnClickOutside={true}
                  onClose={closeImageViewer}
                />
              )}

            </div>
          </div>
        </div>


        <br />
        <br />




        {/* Robocentre Section */}
        <div className='container-fluid pt-5 pb-5' style={{ backgroundColor: "#0000FE" }}>
          <div className='container pt-5 pb-5'>

            <div className='row'>

              <div className='col-md-6'>
                <img className='img-fluid' src={peopleGroup} alt='gblox' id='roboCenImg' />
              </div>

              <div className='col-md-6' id='thatHolder2'>
                <img className='pb-4' src={robocentre_logo} alt='robo' />

                <div id="contentHolder">
                  <h3 className='pt-3 text-white' id="joinText">Where Kids & Teens Learn To Become Innovators</h3>

                  <p className='pt-5 pb-5 text-white' id='servicesText'>
                    We help Parents turn their children’s interests into real-world STEM skills.
                    Our curriculum is developed to empower children to become the future scientist,
                    engineers, inventors and entrepreneurs.
                  </p>

                  <Link className="text-center" to="#" id='signup-btn'>Explore our courses</Link>
                </div>
              </div>

            </div>
          </div>
        </div>




        <Footer />




      </div>
    </>
  )
}

export default Home