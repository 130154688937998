import React from "react";
import fbIcon from '../images/fb_icon.png';
import tweetIcon from '../images/tweet_icon.png';
import instaIcon from '../images/insta_icon.png';
import tubeIcon from '../images/tube_icon.png';
import ticktokIcon from '../images/tiktock_icon.png';
import linkedIcon from '../images/linked_icon.png';

const SocialIcons = () => {
  return (
    <>
      <ul className="list-group-horizontal" id="socialList2">
        <li className="list-group-item border-0">
          <a
            href="https://www.facebook.com/MingoBlox"
            target="_blank"
            rel="noreferrer"
          >
            <img id="socialLink" src={fbIcon} alt='...' />
          </a>

          <a
            href="https://twitter.com/MingoBlox"
            target="_blank"
            rel="noreferrer"
          >
            <img id="socialLink" src={tweetIcon} alt='...' />
          </a>

          <a
            href="https://www.instagram.com/mingo_blox/ "
            target="_blank"
            rel="noreferrer"
          >
            <img id="socialLink" src={instaIcon} alt='...' />
          </a>

          <a
            href="https://www.youtube.com/channel/UCjUNuTXIyNIeHpRKag0H_NA"
            target="_blank"
            rel="noreferrer"
          >
            <img id="socialLink" src={tubeIcon} alt='...' />
          </a>

          <a
            href="https://www.tiktok.com/@mingo_blox"
            target="_blank"
            rel="noreferrer"
          >
            <img id="socialLink" src={ticktokIcon} alt='...' />
          </a>

          <a
            href="https://www.linkedin.com/company/mingoblox/"
            target="_blank"
            rel="noreferrer"
          >
            <img id="socialLink" src={linkedIcon} alt='...' />
          </a>
        </li>
      </ul>
    </>
  );
};

export default SocialIcons;
