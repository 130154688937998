import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../../css/account.css';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

const Account = () => {

    const [userData, setUserData] = useState();
    const [loaded, setLoaded] = useState(false);
    const [token, setToken] = useState();



    //Get Data from Storage onLoad
    useEffect(() => {
        var theToken = localStorage.getItem("token");
        setToken(theToken);

        if (!theToken) {
            window.location.href = "/signin";
        }
        else {

            var gData = localStorage.getItem("userData");
            var gotData = JSON.parse(gData);
            if (gData) {
                setUserData(gotData);
                console.log(JSON.parse(gData));
                setLoaded(true);
            } else {
                setUserData("---");
            }

        }
    }, []);



    return (
        <>

            <Navbar />

            <div className='container' style={{ marginTop: "5rem", marginBottom: "5rem" }}>
                <div className='card' id="account_card">
                    <div className='card-body'>
                        <h4>Account Overview</h4>
                        <hr />

                        <div className='row pt-3'>
                            <div className='col-md-6 pt-2'>
                                <div className='card'>
                                    <div className='card-header'>ACCOUNT DETAILS</div>
                                    <div className='card-body'>
                                        <p style={{ fontSize: "22px" }}>{(!loaded && "---") || userData['first_name'] + " " + userData['last_name']}</p>
                                        <p className='text-secondary' id="ddTextt">{(!loaded && "---") || userData['email']}</p>
                                        <p className='text-secondary'>{(!loaded && "---") || userData['phone']}</p>
                                    </div>
                                </div>
                            </div>


                            <div className='col-md-6 pt-2'>
                                <div className='card'>
                                    <div className='card-header'>
                                        MY ADDRESS (1)
                                        <Link className='fa-pull-right' to="#" id="new_address" data-bs-toggle="modal" data-bs-target="#addressDetailsModal">
                                            NEW ADDRESS
                                        </Link>
                                    </div>
                                    <div className='card-body'>

                                        <div className='container'>
                                            <div className='card' id="address_cardy">
                                                <div className='card-body'>
                                                    <p style={{ fontSize: "18px" }}>Ghana</p>
                                                    <p className='text-secondary' id="ddTextt">Accra</p>
                                                    <p className='text-secondary' id="ddTextt">Ablekuma, Pentecost Junction</p>
                                                    <p className='text-secondary' id="ddTextt">GH - 4888545H / 43</p>
                                                </div>
                                                <div className='card-footer bg-white'>
                                                    <Link className='fa-pull-right mx-3' to="#" id="action_icons"><i className='fa fa-trash'></i></Link>
                                                    <Link className='fa-pull-right' to="#" id="action_icons"><i className='fa fa-pen'></i></Link>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row pt-3'>
                            <div className='col-md-6 pt-2'>
                                <div className='card'>
                                    <div className='card-header'>MINGOBLOX STORE CREDIT</div>
                                    <div className='card-body'>
                                        <div className='pt-2' id="wallet">
                                            <i className='fa fa-wallet fa-lg' style={{ fontSize: "30px" }}></i>
                                            <h4>GH¢ 0.00</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className='col-md-6 pt-2'>
                                <div className='card'>
                                    <div className='card-header'>NEWSLETTER PREFERENCES</div>
                                    <div className='card-body'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Footer />








            {/* Address Details Modal */}
            <div className="modal fade" id="addressDetailsModal" tabindex="-1" aria-labelledby="orderDetailsModal" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Add a new address</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">

                            <div className='row'>
                                <div className='col-md-6 pt-2'>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="f__name" placeholder='First Name' />
                                        <label for="f__name">First Name</label>
                                    </div>
                                </div>

                                <div className='col-md-6 pt-2'>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="l__name" placeholder='Last Name' />
                                        <label for="l__name">Last Name</label>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-4 pt-2'>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="c__code" placeholder='Country Code' />
                                        <label for="c__code">Country Code</label>
                                    </div>
                                </div>

                                <div className='col-md-4 pt-2'>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="tel__num" placeholder='Phone Number' />
                                        <label for="f__name">Phone Number</label>
                                    </div>
                                </div>

                                <div className='col-md-4 pt-2'>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="addTel__num" placeholder='Additional Phone Number' />
                                        <label for="l__name">Additional Phone Number</label>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12 pt-2'>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="res__add" placeholder='Residential Address' />
                                        <label for="res__add">Residential Address</label>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12 pt-2'>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="gps__add" placeholder='GPS Address' />
                                        <label for="gps__add">GPS Address</label>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-6 pt-2'>
                                    <div class="form-floating">
                                        <select class="form-select" id="countrySelect" aria-label="Floating label select">
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                        <label for="countrySelect">Choose Country</label>
                                    </div>
                                </div>

                                <div className='col-md-6 pt-2'>
                                    <div class="form-floating">
                                        <select class="form-select" id="citySelect" aria-label="Floating label select2">
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                        <label for="citySelect">Select City</label>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn" id="track_order">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Account