import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from '../components/Navbar';
import '../css/style.css';
import about_banner from '../images/about_banner.png';
import visionImg from '../images/visionImg.png';
import missionImg from '../images/missionImg.png';
import whoImg from '../images/whoImg.png';
import braNii from '../images/braNii.png';
import braCello from '../images/braCello.png';
import Testimonials from '../components/Testimonials';
import Footer from '../components/Footer';




const About = () => {





  return (
    <>
        <Navbar />


        {/* Banner Section */}
        <div className='mainDiv'>
            <img className='pt-2 img-fluid' id='bannerImg' src={about_banner} alt='aboutImg' style={{width:"100%", objectFit: "cover"}} />

            <div className='text-white text-center' id="bannerContent">
                <h1>About Us</h1>

                <p className='pt-3'>
                    We are a Robotics and STEM Education Company based in Accra, Ghana
                </p>
            </div>
        </div>


        {/* Who we are */}
        <div className='container-fluid pt-5 pb-5' id='whoWeAre' style={{backgroundColor: "#fff"}}>
            <div className='container'>
                <div className='row pt-5'>
                    <div className='col-md-6 text-center'>
                        <img src={whoImg} alt='vision' width='60%' />
                    </div>

                    <div className='col-md-6 pt-5' style={{width: "450px"}}>
                        <h2>Who We Are</h2>

                        <p className='pt-2' id="aboutText">
                            Mingo Blox LLC is a technology firm that specializes in the design, 
                            development, manufacture and sale of a variety of programmable educational 
                            robots, science, technology, engineering, and mathematics (STEM)-focused educational
                            tools as well as social robots, robotics and intelligent systems with global best practices 
                            fully incorporated and adhered to at all stages of products development and service offerings. <br /> <br />

                            The company’s products and service offerings are backed by a highly competent leadership, 
                            products engineering, products development and service delivery team with several decades of 
                            combined experience in designing, developing and supporting educational robotic technologies, 
                            STEM tools, social robotic technologies, and intelligent systems. <br /><br />

                            Our product development and rollout hallmark is carved in beauty, durability, friendliness, 
                            ease of use, affordability, availability, accessibility, and scalability.
                        </p>
                    </div>
                </div>
            </div>
        </div>


        {/* Our Vision */}
        <div className='container-fluid pt-5 pb-5' id='theVision'>
            <div className='container'>
                <div className='row pt-5'>
                    <div className='col-md-6 pt-5' style={{width: "450px"}}>
                        <h2>Our Vision</h2>

                        <p className='pt-2' id="aboutText">
                            We envisage becoming the premier Robotics
                            and Artificial Intelligence (AI) Company in the
                            world. We envisage becoming the premier Robotics
                            and AI.
                        </p>
                    </div>

                    <div className='col-md-6 text-center'>
                        <img src={visionImg} alt='vision' width='60%'/>
                    </div>
                </div>
            </div>
        </div>



        {/* Our Mission */}
        <div className='container-fluid pt-5 pb-5' id='theMission' style={{backgroundColor: "#fff"}}>
            <div className='container'>
                <div className='row pt-5' id='theMission2'>
                    <div className='col-md-6 text-center'>
                        <img src={missionImg} alt='vision' width='60%' />
                    </div>

                    <div className='col-md-6 pt-5' style={{width: "450px"}}>
                        <h2>Our Mission</h2>

                        <p className='pt-2' id="aboutText">
                            We utilize next-generation Robotics and Artificial Intelligence (AI) technologies 
                            to deliver solutions to the many challenges that confront the world we live in. <br /> <br />

                            Our solutions help improve productivity in the corporate world, enhance life for 
                            the global citizenry, facilitate employment creation, and accelerate economic growth and development.
                        </p>
                    </div>
                </div>
            </div>
        </div>







        {/* YOur Mission is Ours */}
        <div className='container-fluid pt-5 pb-5'>
            <div className='container'>
                <div className='row pt-5'>

                    <div className='col-md-6 pt-5' style={{width: "450px"}}>
                        <h2>Mingo Blox</h2>
                        <h5>Your mission is ours</h5>

                        <p className='pt-2' id="aboutText">
                            At Mingo Blox, our primary mandate necessitates us to vigorously and successfully drive the 3H in 
                            Robotics and STEM Education (i.e., Head – Heart – Hand) in Ghana, across Africa and across the globe. <br /><br />

                            Our mission is to reboot robotics and STEM education delivery in all jurisdictions globally in our 
                            quest to favorably transform the way students across all ages and abilities learn, create, and invent. 
                            We provide them with comprehensive educational solutions in engineering, manufacturing, automation 
                            and other industrial technologies driven by our pedagogic resolve to produce industry-ready 
                            workforce, innovators and entrepreneurs for a better tomorrow. <br /> <br />

                            Together with the traditional educators (i.e., partner schools), we work to facilitate students 
                            on our programs to acquire the necessary robotics and STEM-focused skills and competencies which 
                            learners critically need to excel in education, in industry, in life, so as to create a better 
                            future for themselves and a better world going forward. 
                        </p>
                    </div>

                    <div className='col-md-6 text-center'>
                        <img src={braNii} alt='vision' width='80%' style={{float: "right"}} />
                    </div>
                </div>
            </div>


            <div className='container'>
                <div className='row pt-5'>
                    <div className='col-md-6 text-center'>
                        <img src={braCello} alt='vision' width='80%' />
                    </div>

                    <div className='col-md-6 pt-5' style={{width: "450px"}}>

                        <p className='pt-2' id="aboutText">
                            In our secondary mandate, we focus on building social robotics and intelligent systems capabilities. 
                            In this mandate, we concept, design, engineer, build and successfully rollout social robots and 
                            intelligent systems for all industry players – from the health sector, aerospace, aviation, military, 
                            manufacturing, warehousing, systems automation, services delivery, supply chains, hospitality, retail, 
                            banking, agriculture, transportation through to, delighting customers at all levels of the corporate 
                            echelons – as well as consumer robots for individual households.  
                        </p>
                    </div>
                </div>
            </div>
        </div>









        {/* Our Team 
        <div className='container pt-5 pb-5' id='theTeam'>
            <div className='row pt-5'>
                <h2 className='text-center'>Meet Our Management Team</h2>

                <p className='text-center pt-2' id="aboutText">
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                </p>

                <div className='container' style={{width: "800px"}}>
                    <div className='row'>
                        <div className='col-md-4 text-center pt-5'>
                            <img src={benImg} alt='ben' />

                            <h4 className='pt-3'>Ben Nortey</h4>

                            <small id="aboutText">Founder / CEO</small>
                        </div>

                        <div className='col-md-4 text-center pt-5'>
                            <img src={princeImg} alt='ben' />

                            <h4 className='pt-3'>Prince Akuoko</h4>

                            <small id="aboutText">Chief Business Officer</small>
                        </div>

                        <div className='col-md-4 text-center pt-5'>
                            <img src={dinahImg} alt='ben' />

                            <h4 className='pt-3'>Dinah Nortey</h4>

                            <small id="aboutText">General Manager</small>
                        </div>

                        <div className='col-md-4 text-center pt-5'>
                            <img src={joeImg} alt='ben' />

                            <h4 className='pt-3'>Joseph Quartey</h4>

                            <small id="aboutText">Product Manager</small>
                        </div>

                        <div className='col-md-4 text-center pt-5'>
                            <img src={chrisImg} alt='ben' />

                            <h4 className='pt-3'>Chris Kim</h4>

                            <small id="aboutText">Lorem ipsum</small>
                        </div>
                    </div>
                </div>

            </div>
        </div>*/}




        <Testimonials />




        <Footer />



    </>
  )

}

export default About