import React from 'react';
import consImg from '../images/construction.png';

const Offline = () => {

  return (

    <>
        <div className='container-fluid'>
            <h2 className='text-center pt-5' style={{fontWeight: "bold", fontSize: "42px"}}>
                Website Under Construction
            </h2>

            <p className='text-center'>
                Please check back in some few hours...
            </p>

            <div className='text-center pt-2'>
                <img className='img-fluid' src={consImg} width="600px" alt='...' />
            </div>

            <p className='text-center pt-5'>
                You can contact our support team on <strong>(+233) 024-444-6002</strong>
            </p>
        </div>
    </>

  )

}

export default Offline