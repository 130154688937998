import React from 'react';
import '../css/style.css';
import 'animate.css';
import mingoBuyImg from '../images/mingoBuyImg.png';




const Youtube = () => {

  return (
    <>
        <div className='container-fluid pt-5 pb-5' id="youtubeBack">
            <div className='container pt-3' style={{paddingLeft: "4rem", paddingRight: "4rem"}} id="servContainer">
                  <h2 className='text-center text-white' id='servicesHead8'>
                      <strong>Mingo Robot</strong>
                  </h2>

                  <p className='text-center text-white pt-1 pb-4' id='servicesText8'>
                    An educational tool to explore Basic to Advance Coding, <br /> Electronics and STEM
                  </p>

                  <div className='row'>

                    <div className='col-md-4 pt-3'>
                        <div className='card' id='tubeCardMain'>
                          <div className='card-body text-center' id='tubeCard'>
                            <img src={mingoBuyImg} alt='...' />
                            
                            <div className='text-center pt-5'>
                              <a href="/mingo_robot" id='explore-btn'>Buy Now</a>
                            </div>
                          </div>
                        </div>
                    </div>

                    <div className='col-md-8 pt-3' id="tubeCardMain">
                      <iframe id="theVideo" src="https://www.youtube.com/embed/NPwW2QSVBDA?rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
                    </div>

                  </div>
            </div>
        </div>

    </>
  )

}

export default Youtube