import React from 'react';
import '../css/style.css';
import supportBanner from '../images/supportBanner.png';
import logoImg from '../images/logo-white.png';
import mingo_bot from '../images/mingo_bot.png';
import gblox_mobile from '../images/gblox_mobile.png';
import gblox_pc from '../images/gblox_pc.png';
import learnerImg from '../images/learner.png';
import parentImg from '../images/parent.png';
import schoolImg from '../images/school.png';
import celloBot from '../images/celloBot.png';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';



const Support = () => {

  return (
    <>

        {/* Banner Section */}
        <div className='mainDiv'>
            <div className='pt-4' id="theLogoDiv">
                <a href="/"><img src={logoImg} alt='logo' /></a> <span id="supText">Support</span>
            </div>
            <img src={supportBanner} alt='aboutImg' id="supImg" width='100%' />

            <div className='text-white text-center' id="supBannerContent">
                <h1 id="supHead">Having some difficulties?</h1>

                <p className='pt-3 pb-2' id="supSub">
                    We are just a click away...
                </p>

                <div className="p-1 bg-light rounded rounded-pill shadow-sm mb-4">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <button id="button-addon2" type="submit" className="btn btn-link text-secondary"><i className="fa fa-search"></i></button>
                        </div>
                        <input type="search" placeholder="What are you searching for?" aria-describedby="button-addon2" className="form-control border-0 bg-light rounded-pill" />
                    </div>
                </div>
            </div>



            <div className='container pt-5'>
                <h2 className='text-center pt-3 pb-3'>Hardware & Robot kit</h2>

                <div className='card mb-5' id='dCard1'>
                    <div className='card-body' id='dCard1Pro'>

                        <div className='container'>
                            <div className='row text-center'>
                                <div className='col-md-2'></div>

                                <div className='col-md-4 pt-2 pb-2'>
                                    <Link to="#" style={{textDecoration: "none"}}>
                                        <div className='card' id='genCard'>
                                            <div className='card-body pt-5' id='genCardPro'>
                                                <img src={mingo_bot} alt='bot' />

                                                <p className='pt-4' id='supportText'>Mingo</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className='col-md-4 pt-2 pb-2'>
                                    <Link to="#" style={{textDecoration: "none"}}>
                                        <div className='card' id='genCard'>
                                            <div className='card-body' id='genCardPro'>
                                                <img src={celloBot} alt='bot' />

                                                <p className='pt-2' id='supportText'>Cello</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className='col-md-2'></div>
                            </div>
                        </div>

                    </div>
                </div>



                <h2 className='text-center pt-5 pb-3'>Software</h2>

                <div className='card mb-5' id='dCard2'>
                    <div className='card-body' id='dCard1Pro'>

                        <div className='container'>
                            <div className='row text-center'>
                                <div className='col-md-2'></div>

                                <div className='col-md-4 pt-2 pb-2'>
                                    <Link to="#" style={{textDecoration: "none"}}>
                                        <div className='card' id='genCard'>
                                            <div className='card-body pt-5' id='genCardPro'>
                                                <img className='pt-3' src={gblox_pc} alt='bot' />

                                                <p className='pt-4' id='supportText'>gblox</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className='col-md-4 pt-2 pb-2'>
                                    <Link to="#" style={{textDecoration: "none"}}>
                                        <div className='card' id='genCard'>
                                            <div className='card-body pt-5' id='genCardPro'>
                                                <img className='pt-4' src={gblox_mobile} alt='bot' />

                                                <p className='pt-2' id='supportText'>gblox Mobile</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className='col-md-2'></div>
                            </div>
                        </div>

                    </div>
                </div>





                <h2 className='text-center pt-5 pb-3'>RoboCentre</h2>

                <div className='card mb-5' id='dCard3'>
                    <div className='card-body' id='dCard1Pro'>

                        <div className='container'>
                            <div className='row text-center'>
                                <div className='col-md-4 pt-2 pb-2'>
                                    <Link to="#" style={{textDecoration: "none"}}>
                                        <div className='card' id='genCard'>
                                            <div className='card-body pt-5' id='genCardPro'>
                                                <img src={learnerImg} alt='bot' />

                                                <p className='pt-4' id='supportText'>Learner</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className='col-md-4 pt-2 pb-2'>
                                    <Link to="#" style={{textDecoration: "none"}}>
                                        <div className='card' id='genCard'>
                                            <div className='card-body pt-5' id='genCardPro'>
                                                <img src={parentImg} alt='bot' />

                                                <p className='pt-2' id='supportText'>Parent</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className='col-md-4 pt-2 pb-2'>
                                    <Link to="#" style={{textDecoration: "none"}}>
                                        <div className='card' id='genCard'>
                                            <div className='card-body pt-5' id='genCardPro'>
                                                <img src={schoolImg} alt='bot' />

                                                <p className='pt-4' id='supportText'>School</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            

            </div>



            <Footer />


        </div>










    </>
  )

}

export default Support