import React from 'react';
import '../css/style.css';
import campusImg from '../images/campusImg.png';
import makersImg from '../images/makersImg.png';
import eduImg from '../images/eduImg.png';
import ticsImg from '../images/ticsImg.png';
//import 'animate.css';
import { Link } from 'react-router-dom';




const Services = () => {

    return (
        <>
            <div className='container-fluid pt-5 pb-5'>
                <div className='container' style={{ paddingLeft: "3rem", paddingRight: "3rem" }} id="servContainer">
                    <h2 className='text-center pt-4' id='servicesHead'>
                        <strong>Preparing The Next Generation Of <br />STEM Innovators</strong>
                    </h2>

                    <p className='text-center pt-3' id='servicesText'>
                        Our programs aim to teach students innovation, to think critically and use engineering
                        or <br /> technology in imaginative designs or creative approaches to real-world problems while
                        building <br /> on students' mathematics and science base.
                    </p>

                    <div className='row'>
                        <div className='col-md-3 pt-3'>
                            <div className='card mt-4' id="serviceCard">
                                <div className='card-body' id='serviceCardBody'>
                                    <img src={campusImg} alt='codeImg' width='100%' />

                                    <h4 className='pt-4' id='serviceTHead' style={{ fontWeight: "bold" }}>
                                        On-Campus Courses <br /> <br />
                                    </h4>

                                    <p className='pt-1' id='servicesTSub'>
                                        Explore our campus-based <br /> programs: Coding, Maker <br /> Bootcamp & Robotics Masterclass <br />
                                    </p>
                                    <div className='text-center' id="exploreDivv"><Link to="#" id='explore-btn2'>Explore more</Link></div>
                                </div>
                            </div>
                        </div>


                        <div className='col-md-3 pt-3'>
                            <div className='card mt-4' id="serviceCard2">
                                <div className='card-body' id='serviceCardBody2'>
                                    <img src={makersImg} alt='codeImg' width='100%' />

                                    <h4 className='pt-4' id='serviceTHead4' style={{ fontWeight: "bold" }}>
                                        Makers Academy <br /> <br />
                                    </h4>

                                    <p className='pt-0' id='servicesTSub'>
                                        Discover and unlock your <br />  potential through tinkering and <br /> creating solutions using <br /> electronics, software and other <br /> hardware technologies.
                                    </p>
                                    <div className='text-center' id="exploreDivv"><Link to="#" id='explore-btn2'>Explore more</Link></div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-3 pt-3'>
                            <div className='card mt-4' id="serviceCard8">
                                <div className='card-body' id='serviceCardBody3'>
                                    <img src={eduImg} alt='codeImg' width='100%' />

                                    <h4 className='pt-4' id='serviceTHead2' style={{ fontWeight: "bold" }}>
                                        Education Solution for <br /> Schools <br />
                                    </h4>

                                    <p className='pt-1' id='servicesTSub'>
                                        Choose from a variety of <br /> programs for your school: <br /> Curriculum integration, <br /> Co-curricular & Bootcamps  <br />
                                    </p>
                                    <div className='text-center' id="exploreDivv"><Link to="#" id='explore-btn2'>Explore more</Link></div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-3 pt-3'>
                            <div className='card mt-4' id="serviceCard3">
                                <div className='card-body' id='serviceCardBody4'>
                                    <img src={ticsImg} alt='codeImg' width='100%' />

                                    <h4 className='pt-4' id='serviceTHead3' style={{ fontWeight: "bold" }}>
                                        Robotics & STEM Focused <br />  Tools & Setup <br />
                                    </h4>

                                    <p className='pt-1' id='servicesTSub'>
                                        Buy from our range of Robotic <br />  Hardware solutions, STEM kits, <br /> Curriculum, and Makerspace <br />  Setup   <br />
                                    </p>
                                    <div className='text-center' id="exploreDivv"><Link to="#" id='explore-btn2'>Explore more</Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )

}

export default Services