import React from 'react';
import { Link } from 'react-router-dom';
import '../../css/account.css';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import mainRobo from "../../images/mainRobo.png";

const Whishlist = () => {
    return (
        <>

            <Navbar />

            <div className='container' style={{ marginTop: "5rem", marginBottom: "5rem" }}>
                <div className='card' id="account_card">
                    <div className='card-body'>
                        <h4>Items On Whishlist (1)</h4>
                        <hr />

                        <div className='card mt-3'>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <img src={mainRobo} alt="..." width="100%" />
                                    </div>
                                    <div className='col-sm-6'>
                                        <h4>Mingo Robot</h4>

                                        <p className='pt-5' style={{ fontSize: "22px" }}>GH¢ 2000.00</p>
                                        <p id="fakePrice">GH¢ 2500.00</p>
                                    </div>
                                    <div className='col-sm-3'>
                                        <Link className='fa-pull-right' to="/mingo_robot" id="buy_now_btn">BUY NOW</Link>
                                        <Link className='fa-pull-right' to="#" id="remove_btn">
                                            <i className='fa fa-trash'></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Footer />
        </>
    )
}

export default Whishlist