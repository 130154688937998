import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Link } from 'react-router-dom';
import '../../css/account.css';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import mainRobo from "../../images/mainRobo.png";

const Orders = () => {
    return (
        <>

            <Navbar />

            <div className='container' style={{ marginTop: "5rem", marginBottom: "5rem" }}>
                <div className='card' id="account_card">
                    <div className='card-body'>
                        <h4>Orders</h4>
                        <hr />

                        <Tabs>
                            <TabList>
                                <Tab>OPEN ORDERS (0)</Tab>
                                <Tab>CLOSED ORDERS (0)</Tab>
                            </TabList>

                            {/* For Open Orders */}
                            <TabPanel>
                                <div className='card mt-3'>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-sm-3'>
                                                <img src={mainRobo} alt="..." width="100%" />
                                            </div>
                                            <div className='col-sm-6'>
                                                <h5>Mingo Robot</h5>
                                                <p className='text-secondary'>Order No.: 00517855</p>

                                                <p className='text-secondary'>Quantity: 5</p>

                                                <small>Status:</small>
                                                <p id="status_tab">Preparing to Ship</p>

                                                <p>Order Date: 17th October, 2022</p>
                                            </div>
                                            <div className='col-sm-3'>
                                                <Link className='fa-pull-right' to="#" id="see_details_btn" data-bs-toggle="modal" data-bs-target="#orderDetailsModal">SEE DETAILS</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            {/* For Closed Orders */}
                            <TabPanel>
                                <div className='card mt-3'>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-sm-3'>
                                                <img src={mainRobo} alt="..." width="100%" />
                                            </div>
                                            <div className='col-sm-6'>
                                                <h5>Mingo Robot</h5>
                                                <p className='text-secondary'>Order No.: 00517855</p>

                                                <p className='text-secondary'>Quantity: 5</p>

                                                <small>Status:</small>
                                                <p id="status_tab">Completed</p>

                                                <p>Order Date: 10th October, 2022</p>
                                            </div>
                                            <div className='col-sm-3'>
                                                <Link className='fa-pull-right' to="#" id="see_details_btn">
                                                    SEE DETAILS
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>


            <Footer />





            {/* Order Details Modal */}
            <div className="modal fade" id="orderDetailsModal" tabindex="-1" aria-labelledby="orderDetailsModal" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Order Details</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            ...
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn" id="track_order">Track Order</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Orders