import React from 'react';
import { Link } from 'react-router-dom';
import '../css/style.css';
import logo_white from '../images/logo-white.png'
import SocialIcons from './SocialIcons';

const Footer = () => {
  return (
    <>
        <div className='container-fluid pt-5' style={{backgroundColor: "#270064"}}>
          <div className='container' id="theFootContainer">

                <div className="container">
                    <p className='text-center text-white pt-4'>Get notifications via email when our next application goes live</p>

                        <div className='row pt-2' id="theFootFields">

                            <div className='col-md-4' style={{paddingRight: "0"}} id="subsName">
                                <div className="input-group">
                                    <input type="text" id="thatName" className="form-control" placeholder="Name" />
                                </div>
                            </div>

                            <div className='col-md-8' id="subsMail" style={{paddingLeft: "2px"}}>
                                <div className="input-group">
                                    <input type="email" id="thatMail" className="form-control" placeholder="Email" />
                                    <div className="input-group-append">
                                        <button className="btn text-white rounded-0" id="subsBtn" type="button" style={{backgroundColor: "#252363"}}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>


                </div>

                    <div className="row text-white pt-5">

                        <div className="col-md-2 mt-3">

                            <img src={logo_white} alt='logo' />

                        </div>


                        <div className="col-md-2">

                            <h5 className="font-weight-bold mt-3 mb-4"><strong>Company</strong></h5>

                            <ul className="list-unstyled">
                                <li>
                                    <Link to="/about">About</Link>
                                </li>
                                <li className='pt-3'>
                                    <Link to="/about#theVision">Vision</Link>
                                </li>
                                <li className='pt-3'>
                                    <Link to="/about#theMission">Mission</Link>
                                </li>
                            </ul>

                        </div>


                        <div className="col-md-2">

                            <h5 className="font-weight-bold mt-3 mb-4"><strong>Products</strong></h5>

                            <ul className="list-unstyled">
                                <li>
                                    <Link to="#">Mingo Robot</Link>
                                </li>
                                <li className='pt-3'>
                                    <Link to="#">Gblox</Link>
                                </li>
                            </ul>

                        </div>



                        <div className="col-md-2">

                            <h5 className="font-weight-bold mt-3 mb-4"><strong>Resources</strong></h5>

                            <ul className="list-unstyled">
                                <li>
                                    <Link to="#">News</Link>
                                </li>
                                <li className='pt-3'>
                                    <Link to="#">Videos</Link>
                                </li>
                            </ul>

                        </div>



                        <div className="col-md-4">

                            <h5 className="font-weight-bold mt-3 mb-4"><strong>Address: </strong></h5>
                            <p>
                                Presidential Floor, <br /> Mövenpick Hotel, <br /> Accra, Ghana
                            </p>

                            <h5 className="font-weight-bold mt-3 mb-4"><strong>Telephone: </strong></h5>
                            <p>
                                +233 (0) 244 446 002
                            </p>

                            <div className='pt-4'>
                                <SocialIcons />
                            </div>

                        </div>

                    </div>


                <div className='container text-center pt-5'>
                    <div className="footer-copyright text-center text-white pt-5 pb-4">
                        <div className='row'>
                            <div className='col-md-4 text-white'>
                                © 2022 Mingo Blox. All Rights Reserved.
                            </div>

                            <div className='col-md-4 text-white'>
                                <Link to="#" id='footLink'>Privacy Policy</Link>
                            </div>

                            <div className='col-md-4 text-white'>
                                <Link to="#" id='footLink'>Terms of Service</Link>
                            </div>
                        </div>
                    </div>
                </div>

          </div>
        </div>

        
    </>
  )
}

export default Footer