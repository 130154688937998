import React from 'react';
import Navbar from '../components/Navbar';
import '../css/style.css';
import locationImg from '../images/locationImg.png';
import whatsappImg from '../images/whatsappImg.png';
import mailImg from '../images/mailImg.png';
import Footer from '../components/Footer';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';




const Contact = () => {



    //Send Email function
    const sendEmail = () => {

        var theName = document.getElementById('inputName').value;
        var theMail = document.getElementById('inputMail').value;
        var theMsg = document.getElementById('inputMsg').value;

        if(theName === "" || theMail === "" || theMsg === "")
        {
            Swal.fire({
                toast: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                icon: "warning",
                text: "Please fill out all fields!",
            });
        }
        else
        {
            function Send_this_page()
            {
                var send_it = "mailto:robocentregh@gmail.com?subject= New Enquiry From MingoBlox Website";
                send_it += `&body= ${theMsg}. This message is from ${theName}`;
                document.location = send_it;

            }

            Send_this_page();
        }

    }






  return (
    <>
        <Navbar />


        {/* Banner Section */}
        <div className='mainDiv'>

            <div className='container-fluid' id='aboutBanner'>

                <div className='text-white text-center' id="bannerContent2">
                    <h1>Contact Us</h1>

                    <p>
                        We are just a click away...
                    </p>
                </div>
                
            </div>

            <div className='container' id="topCards">
                <div className='row'>
                    <div className='col-md-4 pt-3'>
                        <div className='card' id="contactCard">
                            <div className='card-body text-center' id="cardBody">
                                <img src={locationImg} alt='location' />

                                <p className='pt-4' id="contactText">Movenpick Hotel</p>
                                <p className='pt-0' id="contactText">Ridge, Accra</p>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4 pt-3'>
                        <div className='card' id="contactCard">
                            <div className='card-body text-center' id="cardBody">
                                <img src={whatsappImg} alt='location' />

                                <p className='pt-4' id="contactText">+233 (0) 244 446 002</p>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4 pt-3'>
                        <div className='card' id="contactCard">
                            <div className='card-body text-center' id="cardBody">
                                <img src={mailImg} alt='location' />

                                <p className='pt-4' id="contactText">info@mingoblox.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            


            <div className='container pt-5 pb-5' id='theMap'>

            <br /> <br />
                <div className='row pt-5'>
                    {/*<p className='pt-5' id="contactUs">Send us a messsage</p>

                    <div className='col-md-6 pt-3'>
                        <form>
                            <div className="form-group">
                                <input type="text" className="form-control" id="inputName" placeholder="Enter name" required/>
                            </div>

                            <div className="form-group pt-3">
                                <input type="email" className="form-control" id="inputMail" placeholder="Enter email" required/>
                            </div>

                            <div className="form-group pt-3 pb-4">
                                <textarea type="text" className="form-control" id="inputMsg" placeholder="Enter message" required/>
                            </div>

                            <Link className="btn" id="submitForm-btn" onClick={sendEmail}>Submit</Link>
                        </form>
                    </div>*/}

                    


                    <div className='col-md-12 pt-5'>
                        <div className="mapouter">
                            <div className="gmap_canvas">
                                <iframe title="iframe" width="1100" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=Movenpick%20Ambassador%20Hotel%20Accra,%20Accra&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <Footer />


        </div>










    </>
  )

}

export default Contact