import React from 'react';
import { Link } from 'react-router-dom';
import '../css/style.css';
import slider1 from '../images/home_image1.jpg';
import slider2 from '../images/home_image2.jpg';
import slider3 from '../images/home_image3.jpg';

import slider1Mobile from '../images/home_image1Mobi.jpg';
import slider2Mobile from '../images/home_image2Mobi.jpg';
import slider3Mobile from '../images/home_image3Mobi.jpg';

import 'animate.css';

const Carousel = () => {

  return (
    <>
        <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
            
            <ol className="carousel-indicators mb-5">
                <li data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active"></li>
                <li data-bs-target="#carouselExampleDark" data-bs-slide-to="1"></li>
                <li data-bs-target="#carouselExampleDark" data-bs-slide-to="2"></li>
            </ol>

            <div className="carousel-inner">
                 <div className="carousel-item active" data-bs-interval="5000">
                    
                    <div className='img-fluid' id="slider1Desk">
                        <img src={slider1} className="d-block" alt="..." style={{width: "100vw"}} />
                    </div>

                    <div id='slider1Mobile' style={{display: "none"}} >  
                        <img src={slider1Mobile} className="d-block w-100" alt="..." />
                    </div>
                    
                    <div id="carousel-caption1" className="carousel-caption animate__animated animate__lightSpeedInLeft">
                        <div className='row pb-5'>
                            <div id="headDiv">
                                <h2 id="carouselHead">Inspire their Dreams with Robotics and <br /> STEM Education</h2>

                                <div id="pDiv">
                                    <p className='text-white pt-5 pb-5'>
                                        We provide end-to-end solution by delivering state-of-the-art Robotics
                                        and STEM program. From Robotic Hardware and Software, STEM kits, Curriculum, Training,
                                        Lab Setup for schools and more. We’ve got you covered!
                                    </p>
                                </div>

                                <div id="btnDiv mt-5">
                                    <a className='text-center' href="/mingo_robot" id="learnMore-btn">Order Mingo Robot</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    

                </div>

                <div className="carousel-item" data-bs-interval="5000">
                    <div className='img-fluid' id="slider2Desk">
                        <img src={slider2} className="d-block" alt="..." style={{width: "100vw"}} />
                    </div>

                    <div id='slider2Mobile' style={{display: "none"}} >  
                        <img src={slider2Mobile} className="d-block w-100" alt="..." />
                    </div>

                    <div id="carousel-caption2" className="carousel-caption animate__animated animate__lightSpeedInLeft">
                        <div className='row pb-5'>
                            <div id="headDiv2">
                                <h2 id="carouselHead">Preparing future leaders for the future workplace</h2>

                                <div id="pDiv">
                                    <p className='text-white pt-5 pb-5'>
                                        Our globally researched curriculum puts students ahead of the game. 
                                        Inspired by advances in AI and emerging technologies, we are leaving no child behind. 
                                        We have courses for all age groups. The future belongs to those who prepare for it.
                                    </p>
                                </div>

                                <div id="btnDiv">
                                    <Link className='text-center' to="#" id="learnMore-btn">Learn More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="carousel-item" data-bs-interval="5000">
                    <div className='img-fluid' id="slider3Desk">
                        <img src={slider3} className="d-block" alt="..." style={{width: "100vw"}} />
                    </div>  

                    <div id='slider3Mobile' style={{display: "none"}} >  
                        <img src={slider3Mobile} className="d-block w-100" alt="..." />
                    </div>

                    <div id="carousel-caption3" className="carousel-caption animate__animated animate__lightSpeedInLeft">
                        <div className='row pb-5'>
                            <div className='pb-5' id="headDiv3">
                                <h2 id="carouselHead">We are leaving no <br /> one behind</h2>

                                <div id="pDiv">
                                    <p className='text-white pt-5 pb-5'>
                                        From pre-schools to university, we inspire creativity of learners to not only 
                                        use technology but to create them. Our approach to delivery ensure students 
                                        are well equipped with skills and relevant exposure to take on the world.
                                    </p>
                                </div>

                                <div id="btnDiv">
                                    <Link className='text-center' to="#" id="learnMore-btn">Partner With Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

                   {/*<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>*/}
                
        </div>
    </>
  )
}

export default Carousel