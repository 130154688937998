import React, { useEffect, useState } from "react";
import "../css/checkout.css";
import mainRobo from "../images/mainRobo.png";
import Navbar from "../components/Navbar";
import Youtube from '../components/Youtube';
import Footer from '../components/Footer';

const Checkout2 = () => {
  const [token, setToken] = useState();
  const [loaded, setLoaded] = useState(false);


  //Get Data from Storage onLoad
  useEffect(() => {
    var theToken = localStorage.getItem("token");
    setToken(theToken);

    if (!theToken) {
      window.location.href = "/signin";
    }
    else {

    }
  }, []);



  return (
    <>

      <Navbar />

      <div className="container pt-5 pb-5" id="firstSection" style={{ marginTop: "4rem" }}>

        <div className="row">
          <div className="col-md-8">
            <div className="card" id="purchase_card">
              <div className="card-body">
                <h4 style={{ color: "#646464" }}>Contact Infomation</h4>


              </div>
            </div>
          </div>

          <div className="col-md-4">
            <img src={mainRobo} alt="..." width="400px" />
          </div>
        </div>



      </div>

      <div>
        <Youtube />
      </div>

      <Footer />
    </>
  );
};

export default Checkout2;
